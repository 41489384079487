import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";


const Dashboard = (register) => {
    const [user, setUser] = useState([]);
    
    useEffect(() => {
        console.log(register.register);
        setUser(register.register);
    }, [register])
return (
    <>   
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Nome</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.name} />
            </div>
        </div>
        </div>  
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Username</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.username} />
            </div>
        </div>
        </div> 
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Nickname</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.nickname} />
            </div>
        </div>
        </div>       
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Telefone</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <div className="input-group-prepend">
                <span className="input-group-text">
                <i className="la la-phone" />
                </span>
            </div>
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.phone} placeholder="Phone" />
            </div>
        </div>
        </div>
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">E-mail</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <div className="input-group-prepend">
                <span className="input-group-text">
                <i className="la la-at" />
                </span>
            </div>
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.email} placeholder="Email" />
            </div>
        </div>
        </div>
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Site</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <input type="text" className="form-control form-control-lg form-control-solid" value={user.site} />
            </div>
        </div>
        </div>
    </>
)
}

export default withRouter(Dashboard); 