import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

const AcademicoCategorias = () => {


  useEffect(() => {
    //document.title = ""
  }, []);


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);


  function handleFechaModal() {
    setIsOpen(false);
  }

  function abreModal(){
    console.log("abre model");
    setIsOpen(true);
  }


  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Gerenciar Categorias</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          {/*begin::Item*/}
          <li className="breadcrumb-item text-muted">
            <a href="../../demo1/dist/index.html" className="text-muted text-hover-primary">Dashboard</a>
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="breadcrumb-item">
            <span className="bullet bg-gray-300 w-5px h-2px" />
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="breadcrumb-item text-muted">Acadêmico</li>
          {/*end::Item*/}
          <li className="breadcrumb-item">
            <span className="bullet bg-gray-300 w-5px h-2px" />
          </li>
          {/*begin::Item*/}
          <li className="breadcrumb-item text-dark">Categorias</li>
          {/*end::Item*/}
        </ul>
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
      <p>
        <DateRangePicker
        showToolbar
        showTodayButton
        openTo={"month"}
        showDaysOutsideCurrentMonth
        startText="Check-in"
        endText="Check-out"
        onChange={(date) => console.log(date)}
        
      />
      </p>


      {/*begin::Actions*/}
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        {/*begin::Filter menu*/}
        <div className="m-0">
          {/*begin::Menu toggle*/}
          <a href="#" className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
            {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}Filter</a>
          {/*end::Menu toggle*/}
          {/*begin::Menu 1*/}
          <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_624475ed0ca19">
            {/*begin::Header*/}
            <div className="px-7 py-5">
              <div className="fs-5 text-dark fw-bolder">Filter Options</div>
            </div>
            {/*end::Header*/}
            {/*begin::Menu separator*/}
            <div className="separator border-gray-200" />
            {/*end::Menu separator*/}
            {/*begin::Form*/}
            <div className="px-7 py-5">
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Status:</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <div>
                  <select className="form-select form-select-solid select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_624475ed0ca19" data-allow-clear="true" data-select2-id="select2-data-7-4hkv" tabIndex={-1} aria-hidden="true">
                    <option data-select2-id="select2-data-9-rbwy" />
                    <option value={1}>Approved</option>
                    <option value={2}>Pending</option>
                    <option value={2}>In Process</option>
                    <option value={2}>Rejected</option>
                  </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-8-f9om" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-fr0s-container" aria-controls="select2-fr0s-container"><span className="select2-selection__rendered" id="select2-fr0s-container" role="textbox" aria-readonly="true" title="Select option"><span className="select2-selection__placeholder">Select option</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                </div>
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Member Type:</label>
                {/*end::Label*/}
                {/*begin::Options*/}
                <div className="d-flex">
                  {/*begin::Options*/}
                  <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input className="form-check-input" type="checkbox" defaultValue={1} />
                    <span className="form-check-label">Author</span>
                  </label>
                  {/*end::Options*/}
                  {/*begin::Options*/}
                  <label className="form-check form-check-sm form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" defaultValue={2} defaultChecked="checked" />
                    <span className="form-check-label">Customer</span>
                  </label>
                  {/*end::Options*/}
                </div>
                {/*end::Options*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Notifications:</label>
                {/*end::Label*/}
                {/*begin::Switch*/}
                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" defaultValue name="notifications" defaultChecked="checked" />
                  <label className="form-check-label">Enabled</label>
                </div>
                {/*end::Switch*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Actions*/}
              <div className="d-flex justify-content-end">
                <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Menu 1*/}
        </div>
        {/*end::Filter menu*/}
        {/*begin::Secondary button*/}
        {/*end::Secondary button*/}
        {/*begin::Primary button*/}
        <a href="../../demo1/dist/.html" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Create</a>
        {/*end::Primary button*/}
      </div>
      {/*end::Actions*/}
    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}
  {/*begin::Post*/}
  <div className="post d-flex flex-column-fluid" id="kt_post">
    {/*begin::Container*/}
    <div id="kt_content_container" className="container-xxl">
      {/*begin::Card*/}
      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header border-0 pt-6">
          {/*begin::Card title*/}
          <div className="card-title">
            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height={2} rx={1} transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Buscar usuário" />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Toolbar*/}
            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
              {/*begin::Filter*/}
              {/*end::Filter*/}
              {/*begin::Export*/}
              <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_export_users">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr078.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="12.75" y="4.25" width={12} height={2} rx={1} transform="rotate(90 12.75 4.25)" fill="currentColor" />
                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor" />
                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Exportar XLS</button>
              {/*end::Export*/}
              {/*begin::Add user*/}
              <button onClick={(e)=>{ abreModal() }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_user">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                    <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="currentColor" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Add Categoria</button>
              {/*end::Add user*/}
            </div>
            {/*end::Toolbar*/}
            {/*begin::Group actions*/}
            <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
              <div className="fw-bolder me-5">
                <span className="me-2" data-kt-user-table-select="selected_count" />Selected</div>
              <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
            </div>
            {/*end::Group actions*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body py-4">

        <div className="d-flex flex-stack">
        {/*begin::Folder path*/}
        <div className="badge badge-lg badge-light-primary">
          <div className="d-flex align-items-center flex-wrap">
            {/*begin::Svg Icon | path: icons/duotune/abstract/abs039.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z" fill="currentColor" />
                <path d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <a href="/metronic8/demo1/../demo1/apps/file-manager/folders.html">Keenthemes</a>
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <a href="/metronic8/demo1/../demo1/apps/file-manager/folders.html">themes</a>
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <a href="/metronic8/demo1/../demo1/apps/file-manager/folders.html">html</a>
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <a href="/metronic8/demo1/../demo1/apps/file-manager/folders.html">demo1</a>
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr071.svg*/}
            <span className="svg-icon svg-icon-2x svg-icon-primary mx-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}account</div>
        </div>
        {/*end::Folder path*/}
        {/*begin::Folder Stats*/}
        <div className="badge badge-lg badge-primary">
          <span id="kt_file_manager_items_counter">66 items</span>
        </div>
        {/*end::Folder Stats*/}
      </div>
          
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
            
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"><th className="w-10px pe-2 sorting_disabled" rowSpan={1} colSpan={1} style={{width: '29.25px'}} aria-label="
													">
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" defaultValue={1} />
                      </div>
                    </th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '228.3px'}} aria-label="User: activate to sort column ascending">Categoria</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Role: activate to sort column ascending">Qtd. de Cards</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Last login: activate to sort column ascending">Status</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Last login: activate to sort column ascending">Vínculos</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '164.083px'}} aria-label="Joined Date: activate to sort column ascending">Cadastro</th>
                    <th className="text-end min-w-100px sorting_disabled" rowSpan={1} colSpan={1} style={{width: '100.817px'}} aria-label="Actions">Ações</th></tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  <tr className="odd">
                    {/*begin::Checkbox*/}
                    <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" defaultValue={1} />
                      </div>
                    </td>
                    {/*end::Checkbox*/}
                    {/*begin::User=*/}
                    <td className="d-flex align-items-center">
                      {/*begin:: Avatar */}
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <a href="../../demo1/dist/apps/user-management/users/view.html">
                          <div className="symbol-label">
                            <img src="assets/images/daniel.jpg" alt="Daniel" className="w-100" />
                          </div>
                        </a>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::User details*/}
                      <div className="d-flex flex-column">
                        <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Nome da Categoria</a>
                      </div>
                      {/*begin::User details*/}
                    </td>
                    {/*end::User=*/}
                    {/*begin::Role=*/}
                    <td>
                    <div className="badge badge-light fw-bolder">Free</div>
                    </td>
                    {/*end::Role=*/}
                    {/*begin::Last login=*/}
                    <td data-order="2022-05-18T18:13:25-03:00">
                      <div className="badge badge-light fw-bolder">Não Verificado</div>
                    </td>
                    {/*end::Last login=*/}
                    <td>
                      <div className="badge badge-light fw-bolder">Ontem</div>
                    </td>
                    {/*end::Two step=*/}
                    {/*begin::Joined*/}
                    <td data-order="2022-07-25T18:05:00-03:00">
                      <div className="badge badge-light fw-bolder">22/05/2022 - 12:22:51</div>
                    </td>
                    {/*begin::Joined*/}
                    {/*begin::Action=*/}
                    <td className="text-end">
                      <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Gerenciar
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                        <span className="svg-icon svg-icon-5 m-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}</a>
                    </td>
                    {/*end::Action=*/}
                  </tr>
                  </tbody>
                {/*end::Table body*/}
              </table>
              </div>
              
              <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                    <ul className="pagination">
                      <li className="paginate_button page-item previous disabled" id="kt_table_users_previous">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={0} tabIndex={0} className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="paginate_button page-item active">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={3} tabIndex={0} className="page-link">3</a>
                      </li>
                      <li className="paginate_button page-item next" id="kt_table_users_next">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={4} tabIndex={0} className="page-link">
                          <i className="next" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  <Modal
        size={"lg"}
        show={modalIsOpen}
        onHide={() => handleFechaModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <form id="kt_modal_new_target_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
        {/*begin::Input group*/}
        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
          {/*begin::Label*/}
          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
            <span className="required">Target Title</span>
            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title data-bs-original-title="Specify a target name for future usage and reference" aria-label="Specify a target name for future usage and reference" />
          </label>
          {/*end::Label*/}
          <input type="text" className="form-control form-control-solid" placeholder="Enter Target Title" name="target_title" />
          <div className="fv-plugins-message-container invalid-feedback" /></div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="row g-9 mb-8">
          {/*begin::Col*/}
          <div className="col-md-12 fv-row fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Assign</label>
            <select className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Select a Team Member" name="target_assign" data-select2-id="select2-data-16-27o1" tabIndex={-1} aria-hidden="true">
              <option value data-select2-id="select2-data-18-weke">Select user...</option>
              <option value={1}>Karina Clark</option>
              <option value={2}>Robert Doe</option>
              <option value={3}>Niel Owen</option>
              <option value={4}>Olivia Wild</option>
              <option value={5}>Sean Bean</option>
            </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-17-jvqu" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-target_assign-q0-container" aria-controls="select2-target_assign-q0-container"><span className="select2-selection__rendered" id="select2-target_assign-q0-container" role="textbox" aria-readonly="true" title="Select a Team Member"><span className="select2-selection__placeholder">Select a Team Member</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
            <div className="fv-plugins-message-container invalid-feedback" /></div>
          {/*end::Col*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="d-flex flex-stack mb-8">
          {/*begin::Label*/}
          <div className="me-5">
            <label className="fs-6 fw-bold">Adding Users by Team Members</label>
            <div className="fs-7 fw-bold text-muted">If you need more info, please check budget planning</div>
          </div>
          {/*end::Label*/}
          {/*begin::Switch*/}
          <label className="form-check form-switch form-check-custom form-check-solid">
            <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked="checked" />
            <span className="form-check-label fw-bold text-muted">Allowed</span>
          </label>
          {/*end::Switch*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Input group*/}
        <div className="mb-15 fv-row">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-stack">
            {/*begin::Label*/}
            <div className="fw-bold me-5">
              <label className="fs-6">Notifications</label>
              <div className="fs-7 text-muted">Allow Notifications by Phone or Email</div>
            </div>
            {/*end::Label*/}
            {/*begin::Checkboxes*/}
            <div className="d-flex align-items-center">
              {/*begin::Checkbox*/}
              <label className="form-check form-check-custom form-check-solid me-10">
                <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" defaultValue="email" defaultChecked="checked" />
                <span className="form-check-label fw-bold">Email</span>
              </label>
              {/*end::Checkbox*/}
              {/*begin::Checkbox*/}
              <label className="form-check form-check-custom form-check-solid">
                <input className="form-check-input h-20px w-20px" type="checkbox" name="communication[]" defaultValue="phone" />
                <span className="form-check-label fw-bold">Phone</span>
              </label>
              {/*end::Checkbox*/}
            </div>
            {/*end::Checkboxes*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Input group*/}
        {/*begin::Actions*/}
        <div className="text-center">
          <button type="reset" id="kt_modal_new_target_cancel" className="btn btn-light me-3">Cancel</button>
          <button type="submit" id="kt_modal_new_target_submit" className="btn btn-primary">
            <span className="indicator-label">Submit</span>
            <span className="indicator-progress">Please wait... 
              <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
          </button>
        </div>
        {/*end::Actions*/}
        <div /></form>

          </Modal.Body>
          </Modal>



          </div>
    {/*end::Container*/}
  </div>
    </>
  );

}



export default withRouter(AcademicoCategorias);                  