import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../layout';

import Error404 from '../pages/404'

import Swal from 'sweetalert2';
import { useToasts } from 'react-toast-notifications';
import Login from '../pages/home';


import Dashboard from '../pages/dashboard';

//import Categorias from '../pages/categorias';

import AccountSettings from '../pages/account/settings';
import AccountProfile from '../pages/account/profile';

import AcademicoCards from '../pages/academico/cards';
import AcademicoCategorias from '../pages/academico/categorias';

import UsuariosGerenciar from '../pages/usuarios/gerenciar';

import UsuariosAprovacao from '../pages/usuarios/aprovacao';

import TransacoesGerenciar from '../pages/transacoes';

import Publicacoes from '../pages/publicacoes';

import SugestaoCriadores from '../pages/creator_suggestion';

import Produtos from '../pages/products';

import Plans from '../pages/plans';

import RelatorioPost from '../pages/relatorios/posts';
import SaquesGerenciar from '../pages/saques';
import cagadas from '../pages/cagadas';

//const { addToast } = useToasts();


// Verificar se o usuário está autenticado
const isAuthenticated = () => {
  console.log(localStorage);
  //MATAR NECESSIDADE DO LOGIN!! :
  return true;

  if ("hash_session" in localStorage) {
    return true
  } else {
    Swal.fire(
      'Erro!',
      'Você não está logado, tente novamente.',
      'error'
    )


    return false
  }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const Routes = (props) => (
  <HashRouter>
    <Switch>
      { /* Rotas de Login não necessitam de Auth */}
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>


      { /* ------------------------------------- */}
      <Layout>
        <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />

        

        <AuthenticatedRoute exact path="/account/settings" component={AccountSettings} />
        <AuthenticatedRoute exact path="/account/profile" component={AccountProfile} />

        <AuthenticatedRoute exact path="/academico/cards" component={AcademicoCards} />
        <AuthenticatedRoute exact path="/academico/categorias" component={AcademicoCategorias} />

        <AuthenticatedRoute exact path="/usuarios/gerenciar" component={UsuariosGerenciar} />
        <AuthenticatedRoute exact path="/usuarios/gerenciar/:id" component={UsuariosGerenciar} />
        <AuthenticatedRoute exact path="/usuarios/aprovacao" component={UsuariosAprovacao} />

        <AuthenticatedRoute exact path="/transacoes/" component={TransacoesGerenciar} />
        <AuthenticatedRoute exact path="/transacoes/:id" component={TransacoesGerenciar} />

        <AuthenticatedRoute exact path="/publicacoes/" component={Publicacoes} />
        <AuthenticatedRoute exact path="/publicacoes/:id" component={Publicacoes} />

        <AuthenticatedRoute exact path="/creator/suggestion/" component={SugestaoCriadores} />
        <AuthenticatedRoute exact path="/creator/suggestion/:id" component={SugestaoCriadores} />

        <AuthenticatedRoute exact path="/products/" component={Produtos} />
        <AuthenticatedRoute exact path="/products/:id" component={Produtos} />

        <AuthenticatedRoute exact path="/plans/" component={Plans} />
        <AuthenticatedRoute exact path="/plans/:id" component={Plans} />

        <AuthenticatedRoute exact path="/saques/" component={SaquesGerenciar} />
        <AuthenticatedRoute exact path="/cagadas/" component={cagadas} />


        <AuthenticatedRoute exact path="/relatorios/posts" component={RelatorioPost} />
        <AuthenticatedRoute exact path="/relatorios/posts/:id" component={RelatorioPost} />
      </Layout>

      <Route path="*" component={Error404}></Route>


    </Switch>
  </HashRouter>
);

export default Routes;