import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

const SaquesGerenciar = () => {

  function formata(val) {
    val = parseFloat(val);
    if (val > 0) {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
    return '0,00';
  }

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const { addToast } = useToasts();
  const [users, setUsers] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenSee, setIsOpenSee] = React.useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState({ field: {} });
  const [loadingModal, setLoadingModal] = useState(false);
  const [extract, setExtract] = useState([]);
  const [register, setRegister] = useState([]);
  const [page, setPage] = useState('info');

  function getUsuarios(page) {
    setLoadingUsers(true);
    Api.post("/withdraw/list?page=" + page, searchUsers).then((rps) => {
      setLoadingUsers(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setUsers(rps.data.data);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }
  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getUsuarios(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getUsuarios(1);
  }, [searchUsers])


  function reprocess(withdraw){
    setLoadingUsers(true);
    Api.post("/withdraw/reprocess", {id: withdraw.id}).then((rps) => {
        setLoadingUsers(false);

        Swal.fire(rps.data.message);

    })
  }

  function clear(withdraw){
    setLoadingUsers(true);
    Api.post("/withdraw/clear", {id: withdraw.id}).then((rps) => {
        setLoadingUsers(false);

        addToast(rps.data.message, {
          appearance: rps.data.status===true? "success" : "error",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });

    })
  }

  function reject(withdraw){
    setLoadingUsers(true);
    Api.post("/withdraw/reject", {id: withdraw.id}).then((rps) => {
        setLoadingUsers(false);

        addToast(rps.data.message, {
          appearance: rps.data.status===true? "success" : "error",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });

    })
  }




  return (
    <>
      {/*begin::Toolbar*/}
      <div className="toolbar" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
          {/*begin::Page title*/}
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            {/*begin::Title*/}
            <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Transações</h1>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <span className="h-20px border-gray-300 border-start mx-4" />
            {/*end::Separator*/}
            {/*begin::Breadcrumb*/}
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              {/*begin::Item*/}
              <li class="breadcrumb-item">
                <a href="" class="text-muted">Gerenciar Transação</a>
              </li>
              {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
          </div>
          {/*end::Page title*/}
          {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Toolbar*/}

      {/*begin::Post*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b example example-compact">
            {/*begin::Card header*/}
            {/*<div className="card-header">*/}
            {/*begin::Card title*/}


            {/*begin::Card toolbar*/}
            {/*</div>*/}
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">

              <LoadingOverlay
                active={loadingUsers}
                spinner
              >
                <table className="table table-striped" id="kt_table_users">

                  <thead className="bg-light-primary">
                    <Headerer
                      columns={[
                        { name: 'ID', id: 'withdraws.id', searchable: true, sortable: true },
                        { name: 'Transação', id: 'withdraws.uuid_transaction', searchable: true, sortable: true },
                        { name: 'Usuario', id: 'withdraws.id_user', searchable: true, sortable: true },
                        {
                          name: 'Tipo', id: 'withdraws.type', searchable: false, sortable: true,
                          selects: [
                            { name: "Instantaneo", value: 'INSTANT' }, { name: "Agendado", value: 'WITHDRAW' },
                          ]
                        },
                        { name: 'Valor', id: 'withdraws.value', searchable: true, sortable: true },
                        { name: 'Status', id: 'withdraws.status', searchable: false, sortable: true,
                        selects: [
                          { name: "Pendente", value: 'PENDING' },
                          { name: "Processamento bancário", value: 'BANK_PROCESSING' },
                          { name: "Pago", value: 'PAID' },
                        ]
                         },
                        { name: 'Data', id: 'withdraws.created_at', searchable: true, sortable: true },
                        { name: 'Data pago', id: 'withdraws.paid_at', searchable: true, sortable: true },
                        { name: 'Ação', id: 'action', searchable: false, sortable: false },
                      ]}
                      setSearchOrig={setSearchUsers}
                      searchOrig={searchUsers}
                    />
                  </thead>

                  <tbody className="text-gray-600 fw-bold">
                    {users.map(withdraw => {
                      return (
                        <>
                          <tr>
                            <td className='text-center'>{withdraw.id}</td>
                            <td><small>{withdraw.uuid_withdraw}</small></td>
                            <td>{withdraw.name}<br/><small>{withdraw.id_user}</small></td>
                            <td>{withdraw.type}</td>
                            <td>{withdraw.value}</td>
                            <td>{withdraw.status}</td>
                            <td>{withdraw.created_at}</td>
                            <td>{withdraw.paid_at}</td>
                            <td>
                              <div class="dropdown dropdown-inline dropleft">
                                <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="flaticon-more"></i>
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" onClick={e => { clear(withdraw) }}>Liberar</a>
                                  <a class="dropdown-item" onClick={e => { reject(withdraw) }}>Rejeitar</a>
                                  <a class="dropdown-item" onClick={e => { reprocess(withdraw) }}>Reprocessar</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </LoadingOverlay>

              <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingUsers} />

            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}

          {/*end::Post*/}

        </div>
        {/*end::Container*/}
      </div>
    </>
  );

}

export default withRouter(SaquesGerenciar);                  