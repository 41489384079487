import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../../components/paginator';
import { Headerer } from '../../../components/headerer';
import { ButtonLoading } from '../../../components/ButtonLoading';
import { Link } from 'react-router-dom';

import InformacoesPessoais from "./parts/infopessoais";
import RedesSociais from "./parts/redessociais";
import Comentarios from "./parts/comentarios";
import Compras from './parts/compras';
import Docs from './parts/docs';
import api from '../../../services/api';

const Dashboard = () => {

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const { addToast } = useToasts();
  const [formProductor, setFormProductor] = useState({});
  const [users, setUsers] = useState([]);
  const [usersPhoto, setUsersPhoto] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenSee, setIsOpenSee] = React.useState(false);
  const [modalAlterProductor, setModalAlterProductor] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState({ field: {} });
  const [loadingModal, setLoadingModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [register, setRegister] = useState([]);
  const [page, setPage] = useState('info');

  function getUsuarios(page) {
    setLoadingUsers(true);
    Api.post("/users/list?page=" + page, searchUsers).then((rps) => {
      setLoadingUsers(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setUsers(rps.data.data);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }
  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getUsuarios(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getUsuarios(1);
  }, [searchUsers])

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(user) {
    setIsOpen(true);
    setLoadingModal(true);

    Api.post("/users/listdocs", { id_user: user.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setDocs(rps.data.docs);
    })
  }

  function openModalSee(user) {
    setPage('info');
    setIsOpenSee(true);
    setLoadingModal(true);

    Api.post("/users/selectuser", { id_user: user.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setRegister(rps.data.data);
      setUsersPhoto(rps.data.img);
    })
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalSee() {
    setIsOpenSee(false);
  }

  function closeModalAlter() {
    setModalAlterProductor(false);
  }

  function saveProductor() {
    Api.post("/users/save", formProductor).then((rps) => {

      if (rps.data.status === true) {
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setModalAlterProductor(false);
        getUsuarios(1);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    })
  }

  function valDocs(id, usuario, status, text = null) {
    setLoadingModal(true);
    var post = {
      id_doc: id,
      id_user: usuario,
      new_status: status,
      text: text
    }
    Api.post("/users/updatedocs", post).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status === true) {
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }

      setDocs(rps.data.docs);

      if (rps.data.user !== 0) {
        closeModal();
        setSearchUsers({ field: {} });
      }
    })
  }

  function denyDocument(id, usuario) {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Justificativa / Motivo',
      inputPlaceholder: 'Digite a Justificativa / Motivo...',
      inputAttributes: {
        'aria-label': 'Digite a Justificatica / Motivo'
      },
      showCancelButton: true,
      focus: false,
    }).then((result) => {
      valDocs(id, usuario, "DENIED", result.value)
    });
  }

  function blockedUser(user) {
    var ca = JSON.parse(JSON.stringify(user));

    setLoadingUsers(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja bloquear o Usuário?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('/users/blockeduser', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getUsuarios();
          }

        })
      }
      setLoadingUsers(false);
    })
  }

  function alterUser(user) {
    var ca = JSON.parse(JSON.stringify(user));
    setFormProductor(ca);

    setModalAlterProductor(true);
  }

  function deleteUser(user) {
    var ca = JSON.parse(JSON.stringify(user));

    setLoadingUsers(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja excluir o Produtor?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('/users/deleteuser', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getUsuarios();
          }

        })
      }
      setLoadingUsers(false);
    })
  }

  function approveUser(user) {
    setLoadingUsers(true);

    Swal.fire({
      input: 'textarea',
      inputLabel: 'Justificativa / Motivo',
      inputPlaceholder: 'Digite a Justificativa / Motivo...',
      inputAttributes: {
        'aria-label': 'Digite a Justificatica / Motivo'
      },
      showCancelButton: true,
      focus: false,
    }).then((result) => {
      Api.post('/users/approveUser', { id: user.id, reason: result.value }).then(rps => {
        setLoadingUsers(false);
        if (rps.data.status == true) {
          addToast(rps.data.message, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000
          });
          getUsuarios();
        }

      })
    });
  }


  function loginAs(user, location) {
    api.post("/users/loginAs", { id_user: user.id }).then((rps) => {
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }

      var link = rps.data.link;
      link = link.replace("https://", "https://" + location + ".")

      window.open(link, "_blank");
    })
  }

  function converterR2(user) {
    api.post("/users/convertR2", { id_user: user.id }).then((rps) => {
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }
      Swal.fire(rps.data.message);
    })
  }

  function addPromoBalance(user) {

    Swal.fire({
      title: "Adicionando saldo promocional para " + user.username,
      text: "Digite o valor do saldo promocional utilizado . (Ex: R$ 200,00 = 200.00). NOTA: caso já haja saldo promocional, não será adicionado.",
      input: 'text',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        api.post("users/addPromoBalance", { value: result.value, id_user: user.id }).then((rps) => {
          Swal.fire(rps.data.message);
        })

      }
    });

  }


  //Modla importação de usuários
  const [modalImport, setModalImport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [frmImport, setFrmImport] = useState({});
  function openModalImport(user) {
    setModalImport(true);
    setFrmImport({ id_user: user.id });
  }
  function doImport() {
    setLoadingImport(true);

    api.post("/users/importUsers", frmImport).then((rps) => {
      setLoadingImport(false);
      if (rps.data.status !== true) {
        alert(rps.data.message);
        return;
      }

      setModalImport(false);
      Swal.fire(rps.data.message);
    })
  }


  return (
    <>
      {/*begin::Toolbar*/}
      <div className="toolbar" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
          {/*begin::Page title*/}
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            {/*begin::Title*/}
            <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Usuários</h1>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <span className="h-20px border-gray-300 border-start mx-4" />
            {/*end::Separator*/}
            {/*begin::Breadcrumb*/}
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              {/*begin::Item*/}
              <li class="breadcrumb-item">
                <a href="" class="text-muted">Gerenciar Usuários</a>
              </li>
              {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
          </div>
          {/*end::Page title*/}
          {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Toolbar*/}

      {/*begin::Post*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b example example-compact">
            {/*begin::Card header*/}
            {/*<div className="card-header">*/}
            {/*begin::Card title*/}


            {/*begin::Card toolbar*/}
            {/*</div>*/}
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">

              <LoadingOverlay
                active={loadingUsers}
                spinner
              >
                <table className="table table-striped" id="kt_table_users">

                  <thead className="bg-light-primary">
                    <Headerer
                      columns={[
                        { name: 'ID', id: 'id', searchable: true, sortable: true },
                        { name: 'Nome', id: 'name', searchable: true, sortable: true },
                        { name: 'Username', id: 'username', searchable: true, sortable: true },
                        { name: 'Nickname', id: 'nickname', searchable: true, sortable: true },
                        { name: 'E-mail', id: 'email', searchable: true, sortable: true },
                        { name: 'Saldos', id: 'balance', searchable: false, sortable: true },
                        {
                          name: 'Tipo', id: 'type', searchable: false, sortable: true,
                          selects: [
                            { name: "Produtor", value: 'PRODUCER' }, { name: "Usuário", value: 'USER' },
                            { name: "Admin", value: 'ADMIN' }
                          ]
                        },
                        {
                          name: 'Status Prod', id: 'status', searchable: false, sortable: true,
                          selects: [
                            { name: "Pendente", value: 'PENDING' }, { name: "Reprovado", value: 'DENIED' },
                            { name: "Bloqueado", value: 'BLOCKED' }, { name: "Aprovado", value: 'APPROVED' }
                          ]
                        },
                        { name: 'Ação', id: 'action', searchable: false, sortable: false },
                      ]}
                      setSearchOrig={setSearchUsers}
                      searchOrig={searchUsers}
                    />
                  </thead>

                  <tbody>
                    {users.map(user => {
                      return (
                        <>
                          <tr>
                            <td className='text-center'>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.username}</td>
                            <td>{user.nickname}</td>
                            <td>{user.email}</td>
                            <td>S{user.balance}<br />P{user.balance_promotional}</td>
                            <td className='text-center'>
                              {user.type == "PRODUCER" && <>
                                <span class="label label-danger label-inline mr-2">Produtor</span>
                              </>}
                              {user.type == "USER" && <>
                                <span class="label label-primary label-inline mr-2">Usuário</span>
                              </>}
                              {user.type == "ADMIN" && <>
                                <span class="label label-success label-inline mr-2">Admin</span>
                              </>}
                            </td>
                            <td className='text-center'>
                              {user.status == "PENDING" &&
                                <span class="label label-warning label-inline mr-2">Pendente</span>}
                              {user.status == "APPROVED" &&
                                <span class="label label-success label-inline mr-2">Aprovado</span>}
                              {user.status == "BLOCKED" &&
                                <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                              {user.status == "DENIED" &&
                                <span class="label label-dark label-inline mr-2">Reprovado</span>}
                            </td>
                            <td>
                              <div class="dropdown dropdown-inline dropleft">
                                <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="flaticon-more"></i>
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" onClick={e => { blockedUser(user) }}>Bloquear</a>
                                  <a class="dropdown-item" onClick={e => { alterUser(user) }}>Alterar</a>
                                  <a class="dropdown-item" onClick={e => { deleteUser(user) }}>Excluir</a>
                                  {user.status == "PENDING" && <>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" onClick={e => { openModal(user) }}>Validar Documentos</a>
                                  </>}

                                  {user.status == "DENIED" && <>

                                    <a class="dropdown-item" onClick={e => { approveUser(user) }}>Aprovar produtor negado</a>

                                  </>}

                                  <div class="dropdown-divider"></div>
                                  <a class="dropdown-item" onClick={e => { openModalSee(user) }}>Ver Cadastro/Documentos</a>

                                  <div class="dropdown-divider"></div>
                                  <a class="dropdown-item" onClick={e => { addPromoBalance(user) }}>Adicionar saldo promocional</a>
                                  <div class="dropdown-divider"></div>


                                  {/*<a class="dropdown-item" onClick={e => { converterR2(user) }}>Converter R2</a>*/}
                                  <a class="dropdown-item" onClick={e => { openModalImport(user) }}>Importar lista</a>
                                  <div class="dropdown-divider"></div>

                                  <a class="dropdown-item" onClick={e => { loginAs(user, "hom2") }}>Logar como - HOM2</a>
                                  <a class="dropdown-item" onClick={e => { loginAs(user, "www") }}>Logar como</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </LoadingOverlay>

              <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingUsers} />

            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}

          {/*end::Post*/}

          <Modal enforceFocus={false} size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
            <Modal.Header>
              <Modal.Title>Validar Documentos <h3 class="label label-warning label-inline font-size-h3 ml-20">Pendente</h3></Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              <div className="row ml-3 mr-3">
                <div className="col-md-12">
                  <div className='row mt-3'>
                    <div className='col-md-6'><b>Foto</b></div>
                    <div className='col-md-2'><b>Documento</b></div>
                    <div className='col-md-2'><b>Status</b></div>
                    <div className='col-md-2'><b>Ação</b></div>
                  </div>
                  {docs.map(doc => {
                    return (
                      <>
                        <div className='row mt-3 mb-3'>
                          <div className='col-md-6'>
                            <img className="img-fluid img-thumbnail" src={doc.document} />
                          </div>
                          <div className='col-md-2'>
                            {doc.type == "cnh_frente" && <label>CNH Frente</label>}
                            {doc.type == "cnh_verso" && <label>CNH Verso</label>}
                            {doc.type == "selfie" && <label>Selfie</label>}
                          </div>
                          <div className='col-md-2'>
                            {doc.status == "PENDING" &&
                              <span class="label label-warning label-inline mr-2">Pendente</span>}
                            {doc.status == "APPROVED" &&
                              <span class="label label-success label-inline mr-2">Aprovado</span>}
                            {doc.status == "BLOCKED" &&
                              <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                            {doc.status == "DENIED" &&
                              <span class="label label-dark label-inline mr-2">Reprovado</span>}
                          </div>
                          <div className='col-md-2'>
                            {doc.status == "PENDING" && <>
                              <button type="button" class="btn btn-light-danger btn-circle btn-icon btn-sm m-2"
                                onClick={e => { denyDocument(doc.id, doc.id_user) }}>
                                <i class="flaticon2-delete"></i>
                              </button>
                              <button type="button" class="btn btn-light-success btn-circle btn-icon btn-sm m-2"
                                onClick={e => { valDocs(doc.id, doc.id_user, "APPROVED") }}>
                                <i class="flaticon2-check-mark"></i>
                              </button>
                            </>}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>



          <Modal size={'xl'} centered show={modalIsOpenSee} onHide={() => closeModalSee()}>
            <Modal.Header>
              <Modal.Title>Ver Cadastro</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalSee() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Profile Personal Information*/}
                  <div className="d-flex flex-row mt-8 mb-8">
                    {/*begin::Aside*/}
                    <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-300px" id="kt_profile_aside">
                      {/*begin::Profile Card*/}
                      <div className="card card-custom card-stretch">
                        {/*begin::Body*/}
                        <div className="card-body">
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                              <div className="symbol-label" style={{ backgroundImage: "url(" + usersPhoto + ")" }} />
                            </div>
                            <div>
                              <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{register.name}</a>
                              <div className="text-muted">{register.type}</div>
                              <div className="mt-2">
                                {/*<a href="#" className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Chat</a>*/}
                              </div>
                            </div>
                          </div>
                          {/*end::User*/}
                          {/*begin::Contact*/}
                          <div className="py-9">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <span className="font-weight-bold mr-2">E-mail:</span>
                              <a href="#" className="text-muted text-hover-primary">{register.email}</a>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <span className="font-weight-bold mr-2">Telefone:</span>
                              <span className="text-muted">{register.phone}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="font-weight-bold mr-2">Localização:</span>
                              <span className="text-muted">{register.city}/{register.state}</span>
                            </div>
                          </div>
                          {/*end::Contact*/}
                          {/*begin::Nav*/}
                          <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('info'); }} className={`navi-link py-4 ${page === "info" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Informações Pessoais</span>
                              </a>
                            </div>
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('documentos'); }} className={`navi-link py-4 ${page === "documentos" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3" />
                                        <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Documentos</span>
                              </a>
                            </div>
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('redes'); }} className={`navi-link py-4 ${page === "redes" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                                        <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Redes Sociais</span>
                              </a>
                            </div>
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('comentarios'); }} className={`navi-link py-4 ${page === "comentarios" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M9.61764706,5 L8.73529412,7 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L9.61764706,5 Z M14.3823529,5 L21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L15.2647059,7 L14.3823529,5 Z M6.08823529,13 L5.20588235,15 L3,15 C2.44771525,15 2,14.5522847 2,14 C2,13.4477153 2.44771525,13 3,13 L6.08823529,13 Z M17.9117647,13 L21,13 C21.5522847,13 22,13.4477153 22,14 C22,14.5522847 21.5522847,15 21,15 L18.7941176,15 L17.9117647,13 Z M7.85294118,9 L6.97058824,11 L3,11 C2.44771525,11 2,10.5522847 2,10 C2,9.44771525 2.44771525,9 3,9 L7.85294118,9 Z M16.1470588,9 L21,9 C21.5522847,9 22,9.44771525 22,10 C22,10.5522847 21.5522847,11 21,11 L17.0294118,11 L16.1470588,9 Z M4.32352941,17 L3.44117647,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L4.32352941,17 Z M19.6764706,17 L21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L20.5588235,19 L19.6764706,17 Z" fill="#000000" opacity="0.3" />
                                        <path d="M11.044,5.256 L13.006,5.256 L18.5,19 L16,19 L14.716,15.084 L9.19,15.084 L7.5,19 L5,19 L11.044,5.256 Z M13.924,13.14 L11.962,7.956 L9.964,13.14 L13.924,13.14 Z" fill="#000000" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Comentários</span>
                              </a>
                            </div>
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('compras'); }} className={`navi-link py-4 ${page === "compras" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) " />
                                        <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Compras</span>
                              </a>
                            </div>
                          </div>
                          {/*end::Nav*/}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Profile Card*/}
                    </div>
                    {/*end::Aside*/}
                    {/*begin::Content*/}
                    <div className="flex-row-fluid ml-lg-8">
                      {/*begin::Card*/}
                      <div className="card card-custom card-stretch">
                        {/*begin::Header*/}
                        <div className="card-header py-3">
                          <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">
                              {page === 'info' && 'Informações Pessoais'}
                              {page === 'documentos' && 'Documentos'}
                              {page === 'redes' && 'Redes Sociais'}
                              {page === 'comentarios' && 'Comentários'}
                              {page === 'compras' && 'Compras'}
                            </h3>
                          </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Form*/}
                        <form className="form">
                          {/*begin::Body*/}
                          <div className="card-body">
                            {page === 'info' &&
                              <InformacoesPessoais register={register} />
                            }
                            {page === 'documentos' &&
                              <Docs register={register} />
                            }
                            {page === 'redes' &&
                              <RedesSociais register={register} />
                            }
                            {page === 'comentarios' &&
                              <Comentarios register={register} />
                            }
                            {page === 'compras' &&
                              <Compras register={register} />
                            }
                          </div>
                          {/*end::Body*/}
                        </form>
                        {/*end::Form*/}
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Profile Personal Information*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}


            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalSee(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>



          {/* MODAL ADC ASSINANTE INICIO */}
          <Modal size={'xl'} centered show={modalImport} onHide={() => setModalImport(false)()}>
            <Modal.Header>
              <Modal.Title>Importar assinantes</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalSee() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <Modal.Body>
              Um email por linha. Será importado o plano de 30 dias.
              <br />
              <textarea value={frmImport.list} onChange={e => { setFrmImport({ ...frmImport, list: e.target.value }) }}></textarea>


            </Modal.Body>



            <Modal.Footer>
              <ButtonLoading isLoading={loadingImport} onClick={e => { doImport(); }} className="btn btn-light-primary font-weight-bold">Salvar</ButtonLoading>
              <button type="button" onClick={e => { setModalImport(false); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>
          {/* MODAL ADC ASSINANTE FINAL */}

          {/* MODAL ADC ASSINANTE INICIO */}
          <Modal size={'xl'} centered show={modalAlterProductor} onHide={() => setModalAlterProductor(false)()}>
            <Modal.Header>
              <Modal.Title>Alterar Produtor</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalAlter() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className='row'>
                <div className='col-md-12 form-group'>
                  <label>Nome/Name</label>
                  <input type="text" className='form-control'
                    value={formProductor?.name}
                    onChange={(e) => { setFormProductor({ ...formProductor, name: e.target.value }) }} />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group'>
                  <label>Nickname</label>
                  <input type="text" className='form-control'
                    value={formProductor?.nickname}
                    onChange={(e) => { setFormProductor({ ...formProductor, nickname: e.target.value }) }} />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group'>
                  <label>E-mail</label>
                  <input type="text" className='form-control'
                    value={formProductor?.email}
                    onChange={(e) => { setFormProductor({ ...formProductor, email: e.target.value }) }} />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 form-group'>
                  <label>CPF</label>
                  <input type="text" className='form-control'
                    value={formProductor?.tax_id}
                    onChange={(e) => { setFormProductor({ ...formProductor, tax_id: e.target.value }) }} />
                </div>
                <div className='col-md-6 form-group'>
                  <label>Celular</label>
                  <input type="text" className='form-control'
                    value={formProductor?.phone}
                    onChange={(e) => { setFormProductor({ ...formProductor, phone: e.target.value }) }} />
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 form-group'>
                  <label>Referred</label>
                  <input type="text" className='form-control'
                    value={formProductor?.referred}
                    onChange={(e) => { setFormProductor({ ...formProductor, referred: e.target.value }) }} />
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalAlter(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
              <button type="button" onClick={e => { saveProductor(); }} className="btn btn-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-save" />Salvar</button>
            </Modal.Footer>
          </Modal>
          {/* MODAL ALTERAR PRODUTOR */}


        </div>
        {/*end::Container*/}
      </div>
    </>
  );

}

export default withRouter(Dashboard);                  