import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Api from '../../../../../services/api';
import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';

const UsuariosGerenciar = (register) => {
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);

    useEffect(() => {
      getDocs();
    }, [])

    function getDocs(){
        setLoading(true);
        
        Api.post("/users/listdocs", {id_user: register.register.id}).then((rps) => {
          setLoading(false);
    
          if(rps.data.status!==true){
            Swal.fire({
              title: "Aviso!",
              icon: "warning",
              html: rps.data.message,
              showCloseButton: true,
              showCancelButton: false,
            });
            return;
          }
          setDocs(rps.data.docs);
        })
      }

return (
    <>             
        <LoadingOverlay
            active={loading}
            spinner
            >
        
          <div className="row ml-3 mr-3">
            <div className="col-md-12">
              <div className='row mt-3'>
                <div className='col-md-10'><b>Foto</b></div>
                <div className='col-md-2'><b>Documento</b></div>
              </div>
              {docs.map(doc => {
                  return (
                <>
                  <div className='row mt-3 mb-3'>
                    <div className='col-md-10'>
                      <img className="img-fluid img-thumbnail" src={doc.document} />
                    </div>
                    <div className='col-md-2'>
                      {doc.type == "cnh_frente" && <label>CNH Frente</label>}
                      {doc.type == "cnh_verso" && <label>CNH Verso</label>}
                      {doc.type == "selfie" && <label>Selfie</label>}
                    </div>
                  </div>
                </>
                )
              })}
              </div>
            </div>
          
        </LoadingOverlay>
    </>
)
}

export default withRouter(UsuariosGerenciar); 