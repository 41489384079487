import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

const Cagadas = () => {

    function formata(val) {
        val = parseFloat(val);
        if (val > 0) {
            return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        }
        return '0,00';
    }

    useEffect(() => {
        //document.title = ""
    }, []);

    const [loading, setLoading] = useState(false);

    const defaultFrmCredita = { id_user: '', value: '', descricao: '' };
    const [frmCredita, setFrmCredita] = useState(defaultFrmCredita)
    function credita() {
        if (loading) return;
        setLoading(true);

        Api.post("cagadas/creditaSaldoReal", frmCredita).then((rps) => {
            setLoading(false);

            alert(rps.data.message);

            console.log(rps.data.status);
            if (rps.data.status === true) {
                setFrmCredita(defaultFrmCredita);
            }
        })
    }

    const defaultFrmDebita = { id_user: '', value: '', descricao: '' };
    const [frmDebita, setFrmDebita] = useState(defaultFrmDebita)
    function debitar() {
        if (loading) return;
        setLoading(true);

        Api.post("cagadas/debitarSaldoReal", frmDebita).then((rps) => {
            setLoading(false);

            alert(rps.data.message);

            console.log(rps.data.status);
            if (rps.data.status === true) {
                setFrmDebita(defaultFrmDebita);
            }
        })
    }

    return (
        <>
            {/*begin::Toolbar*/}
            <div className="toolbar" id="kt_toolbar">
                {/*begin::Container*/}
                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                    {/*begin::Page title*/}
                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                        {/*begin::Title*/}
                        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Transações</h1>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <span className="h-20px border-gray-300 border-start mx-4" />
                        {/*end::Separator*/}
                        {/*begin::Breadcrumb*/}
                        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                            {/*begin::Item*/}
                            <li class="breadcrumb-item">
                                <a href="" class="text-muted">Gerenciar Transação</a>
                            </li>
                            {/*end::Item*/}
                        </ul>
                        {/*end::Breadcrumb*/}
                    </div>
                    {/*end::Page title*/}
                    {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Toolbar*/}

            {/*begin::Post*/}
            <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                    {/*begin::Card*/}
                    <div className="card card-custom gutter-b example example-compact">
                        {/*begin::Card header*/}
                        {/*<div className="card-header">*/}
                        {/*begin::Card title*/}


                        {/*begin::Card toolbar*/}
                        {/*</div>*/}
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body">


                            <LoadingOverlay
                                active={loading}
                                spinner
                            >

                                <p>Inserir saldo real para usuário:</p>

                                <p>
                                    ID usuário:
                                    <input value={frmCredita.id_user} onChange={e => { setFrmCredita({ ...frmCredita, id_user: e.target.value }) }}></input>
                                </p>

                                <p>

                                    Valor:
                                    <input value={frmCredita.value} onChange={e => { setFrmCredita({ ...frmCredita, value: e.target.value }) }}></input>


                                </p>

                                <p>

                                    Motivo:
                                    <input value={frmCredita.descricao} onChange={e => { setFrmCredita({ ...frmCredita, descricao: e.target.value }) }}></input>
                                </p>

                                <button onClick={e => { credita() }}>
                                    Confirmar
                                </button>
                                <hr />
                                <p>Debitar saldo real do usuário:</p>

                                <p>
                                    ID usuário:
                                    <input value={frmDebita.id_user} onChange={e => { setFrmDebita({ ...frmDebita, id_user: e.target.value }) }}></input>
                                </p>

                                <p>

                                    Valor:
                                    <input value={frmDebita.value} onChange={e => { setFrmDebita({ ...frmDebita, value: e.target.value }) }}></input>


                                </p>

                                <p>

                                    Motivo:
                                    <input value={frmDebita.descricao} onChange={e => { setFrmDebita({ ...frmDebita, descricao: e.target.value }) }}></input>
                                </p>

                                <button onClick={e => { debitar() }}>
                                    Confirmar
                                </button>

                            </LoadingOverlay>

                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}

                    {/*end::Post*/}

                </div>
                {/*end::Container*/}
            </div>
        </>
    );

}

export default withRouter(Cagadas);                  