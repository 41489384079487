import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

const TransacoesGerenciar = () => {

  function formata(val) {
    val = parseFloat(val);
    if (val > 0) {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
    return '0,00';
  }

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const { addToast } = useToasts();
  const [users, setUsers] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenSee, setIsOpenSee] = React.useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState({ field: {} });
  const [loadingModal, setLoadingModal] = useState(false);
  const [extract, setExtract] = useState([]);
  const [register, setRegister] = useState([]);
  const [page, setPage] = useState('info');

  function getUsuarios(page) {
    setLoadingUsers(true);
    Api.post("/transactions/list?page=" + page, searchUsers).then((rps) => {
      setLoadingUsers(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setUsers(rps.data.data);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }
  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getUsuarios(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getUsuarios(1);
  }, [searchUsers])

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(transaction) {
    setIsOpen(true);
    setLoadingModal(true);

    Api.post("/extracts/listextract", { transaction: transaction.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        Swal.fire({
          title: "Aviso!",
          icon: "warning",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
        setIsOpen(false);
        return;
      }
      setExtract(rps.data.trans);
    })
  }

  function openModalSee(user) {
    setIsOpenSee(true);
    setLoadingModal(true);

    Api.post("/users/selectuser", { id_user: user.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        //Swal.fire(rps.data.message);
        //return;
      }
      setRegister(rps.data.data);
    })
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalSee() {
    setIsOpenSee(false);
  }

  function bloqUser(id) {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Justificativa / Motivo',
      inputPlaceholder: 'Digite a Justificativa / Motivo...',
      inputAttributes: {
        'aria-label': 'Digite a Justificatica / Motivo'
      },
      showCancelButton: true
    });
  }

  function estornar(transaction) {
    setLoadingUsers(true);
    Api.post("/transactions/refund", { id_transaction: transaction.id }).then((rps) => {
      setLoadingUsers(false);

      if (rps.data.status !== true) {
        Swal.fire(rps.data.message);
        return;
      }

      Swal.fire(rps.data.message);
    })
  }


  return (
    <>
      {/*begin::Toolbar*/}
      <div className="toolbar" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
          {/*begin::Page title*/}
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            {/*begin::Title*/}
            <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Transações</h1>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <span className="h-20px border-gray-300 border-start mx-4" />
            {/*end::Separator*/}
            {/*begin::Breadcrumb*/}
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              {/*begin::Item*/}
              <li class="breadcrumb-item">
                <a href="" class="text-muted">Gerenciar Transação</a>
              </li>
              {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
          </div>
          {/*end::Page title*/}
          {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Toolbar*/}

      {/*begin::Post*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b example example-compact">
            {/*begin::Card header*/}
            {/*<div className="card-header">*/}
            {/*begin::Card title*/}


            {/*begin::Card toolbar*/}
            {/*</div>*/}
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">

              <LoadingOverlay
                active={loadingUsers}
                spinner
              >
                <table className="table table-striped" id="kt_table_users">

                  <thead className="bg-light-primary">
                    <Headerer
                      columns={[
                        { name: 'ID', id: 'transactions.id', searchable: true, sortable: true },
                        { name: 'Transação', id: 'transactions.uuid_transaction', searchable: true, sortable: true },
                        { name: 'Gateway', id: 'transactions.gateway', searchable: true, sortable: true },
                        { name: 'ID Gateway', id: 'transactions.external_id', searchable: true, sortable: true },
                        { name: 'Usuario', id: 'transactions.id_payer', searchable: true, sortable: true },
                        { name: 'IP', id: 'transactions.ip_address', searchable: true, sortable: true },
                        {
                          name: 'Tipo', id: 'transactions.type', searchable: false, sortable: true,
                          selects: [
                            { name: "Validação", value: 'VALIDATION' }, { name: "Carteira", value: 'WALLET' },
                            { name: "Assinatura", value: 'SUBSCRIPTION' }, { name: "Pedido", value: 'ORDER' },
                            { name: "Publicação", value: 'POST' }, { name: "Gorjeta", value: 'TIP' }
                          ]
                        },
                        { name: 'Valor', id: 'transactions.value', searchable: true, sortable: true },
                        {
                          name: 'Metodo Pagamento', id: 'transactions.method', searchable: false, sortable: true,
                          selects: [
                            { name: "Pix", value: 'PIX' }, { name: "Boleto", value: 'BOLETO' },
                            { name: "Crédito", value: 'CREDIT' }, { name: "Carteira", value: 'WALLET' },
                            { name: "Picpay", value: 'PICPAY' },
                          ]
                        },
                        { name: 'Data', id: 'transactions.paid_date', searchable: true, sortable: true },
                        {
                          name: 'Status', id: 'transactions.status_internal', searchable: false, sortable: true,
                          selects: [
                            { name: "Sucesso", value: 'SUCCESS' }, { name: "Pendente", value: 'WAITING' },
                            { name: "Estornado", value: 'REFUNDED' }, { name: "Chargeback", value: 'CHARGEDBACK' },
                            { name: "Cancelado", value: 'CANCELLED' }
                          ]
                        },
                        { name: 'Ação', id: 'action', searchable: false, sortable: false },
                      ]}
                      setSearchOrig={setSearchUsers}
                      searchOrig={searchUsers}
                    />
                  </thead>

                  <tbody className="text-gray-600 fw-bold">
                    {users.map(user => {
                      return (
                        <>
                          <tr>
                            <td className='text-center'>{user.id}</td>
                            <td><small>{user.uuid_transaction}</small></td>
                            <td>
                              {user.gateway}
                            </td>
                            <td>
                              <small>{user.external_id}</small>
                            </td>
                            <td>
                              {user.username} / {user.name}
                              <br /><small>{user.id_payer}</small>
                            </td>
                            <td>{user.ip_address}</td>
                            <td className='text-center'>
                              {user.type == "VALIDATION" && <>
                                <span class="label label-danger label-inline mr-2">Validação</span>
                              </>}
                              {user.type == "WALLET" && <>
                                <span class="label label-primary label-inline mr-2">Carteira</span>
                              </>}
                              {user.type == "SUBSCRIPTION" && <>
                                <span class="label label-success label-inline mr-2">Assinatura</span>
                              </>}
                              {user.type == "POST" && <>
                                <span class="label label-success label-inline mr-2">Publicação</span>
                              </>}
                              {user.type == "ORDER" && <>
                                <span class="label label-success label-inline mr-2">Pedido</span>
                              </>}
                              {user.type == "TIP" && <>
                                <span class="label label-success label-inline mr-2">Gorjeta</span>
                              </>}
                            </td>
                            <td className='text-right'>{formata(user.value)}</td>
                            <td className='text-center'>
                              {user.method == "PIX" &&
                                <span class="label label-warning label-inline mr-2">Pix</span>}
                              {user.method == "BOLETO" &&
                                <span class="label label-success label-inline mr-2">Boleto</span>}
                              {user.method == "CREDIT" &&
                                <span class="label label-dark label-inline mr-2">Crédito</span>}
                              {user.method == "WALLET" &&
                                <span class="label label-info label-inline mr-2">Carteira</span>}
                              {user.method == "PICPAY" &&
                                <span class="label label-info label-inline mr-2">Picpay</span>}
                            </td>
                            <td>{user.created_at}</td>
                            <td className='text-center'>
                              {user.status_internal == "SUCCESS" &&
                                <span class="label label-success label-inline mr-2">Sucesso</span>}
                              {user.status_internal == "REFUNDED" &&
                                <span class="label label-info label-inline mr-2">Reembolso</span>}
                              {user.status_internal == "CANCELLED" &&
                                <span class="label label-danger label-inline mr-2">Cancelado</span>}
                              {user.status_internal == "CHARGEDBACK" &&
                                <span class="label label-dark label-inline mr-2">Chargeback</span>}
                              {user.status_internal == "WAITING" &&
                                <span class="label label-warning label-inline mr-2">Aguardando</span>}
                            </td>
                            <td>
                              <div class="dropdown dropdown-inline dropleft">
                                <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="flaticon-more"></i>
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" onClick={e => { estornar(user) }}>Estornar</a>
                                  {/* So aparecer se for credito */}
                                  <a class="dropdown-item" onClick={e => { bloqUser(user) }}>ChargeBackK</a>
                                  <a class="dropdown-item" onClick={e => { openModal(user) }}>Ver Extrato</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </LoadingOverlay>

              <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingUsers} />

            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}

          {/*end::Post*/}

          <Modal size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
            <Modal.Header>
              <Modal.Title>Extrato de Transações</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              <div className="row ml-3 mr-3">
                <div className="col-md-12">
                  <div className='row mt-3'>
                    <div className='col-md-6'><b>Descrição</b></div>
                    <div className='col-md-2'><b>Operação</b></div>
                    <div className='col-md-2'><b>Motivo</b></div>
                    <div className='col-md-2'><b>Valor</b></div>
                  </div>
                  {extract.map(ext => {
                    return (
                      <>
                        <div className='row mt-3 mb-3'>
                          <div className='col-md-6'>
                            {ext.description}
                          </div>
                          <div className='col-md-2'>
                            {ext.operation == "CREDIT" &&
                              <span class="label label-success label-inline mr-2">CrédiTo</span>}
                            {ext.operation == "DEBIT" &&
                              <span class="label label-danger label-inline mr-2">Débito</span>}
                            {ext.operation == "NEUTRAL" &&
                              <span class="label label-primary label-inline mr-2">Neutro</span>}
                          </div>
                          <div className='col-md-2'>
                            {ext.reason == "DEPOSIT" &&
                              <span class="label label-success label-inline mr-2">Depósito</span>}
                            {ext.reason == "WITHDRAW" &&
                              <span class="label label-danger label-inline mr-2">Retirada</span>}
                            {ext.reason == "TRANSFER" &&
                              <span class="label label-primary label-inline mr-2">Transferência</span>}
                            {ext.reason == "TIP" &&
                              <span class="label label-info label-inline mr-2">Gorjeta</span>}
                            {ext.reason == "SUBSCRIPTION" &&
                              <span class="label label-dark label-inline mr-2">Assinatura</span>}
                            {ext.reason == "POST" &&
                              <span class="label label-secondary label-inline mr-2">Publicação</span>}
                            {ext.reason == "SHOP" &&
                              <span class="label label-warning label-inline mr-2">Compras</span>}
                          </div>
                          <div className='col-md-2'>
                            {ext.value}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>



          <Modal size={'lg'} centered show={modalIsOpenSee} onHide={() => closeModalSee()}>
            <Modal.Header>
              <Modal.Title>Ver Cadastro</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalSee() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Profile Personal Information*/}
                  <div className="d-flex flex-row mt-8 mb-8">
                    {/*begin::Aside*/}
                    <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-300px" id="kt_profile_aside">
                      {/*begin::Profile Card*/}
                      <div className="card card-custom card-stretch">
                        {/*begin::Body*/}
                        <div className="card-body">
                          {/*begin::User*/}
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                              <div className="symbol-label" style={{ backgroundImage: 'url("assets/media/users/300_21.jpg")' }} />
                            </div>
                            <div>
                              <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{register.name}</a>
                              <div className="text-muted">{register.type}</div>
                              <div className="mt-2">
                                <a href="#" className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Chat</a>
                              </div>
                            </div>
                          </div>
                          {/*end::User*/}
                          {/*begin::Contact*/}
                          <div className="py-9">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <span className="font-weight-bold mr-2">E-mail:</span>
                              <a href="#" className="text-muted text-hover-primary">{register.email}</a>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <span className="font-weight-bold mr-2">Telefone:</span>
                              <span className="text-muted">{register.phone}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="font-weight-bold mr-2">Localização:</span>
                              <span className="text-muted">{register.city}/{register.state}</span>
                            </div>
                          </div>
                          {/*end::Contact*/}
                          {/*begin::Nav*/}
                          <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('info'); }} className={`navi-link py-4 ${page === "info" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Informações Pessoais</span>
                              </a>
                            </div>
                            <div className="navi-item mb-2">
                              <a onClick={e => { setPage('redes'); }} className={`navi-link py-4 ${page === "redes" ? " active" : ""}`}>
                                <span className="navi-icon mr-2">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                                        <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                </span>
                                <span className="navi-text font-size-lg">Redes Sociais</span>
                              </a>
                            </div>
                          </div>
                          {/*end::Nav*/}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Profile Card*/}
                    </div>
                    {/*end::Aside*/}
                    {/*begin::Content*/}
                    <div className="flex-row-fluid ml-lg-8">
                      {/*begin::Card*/}
                      <div className="card card-custom card-stretch">
                        {/*begin::Header*/}
                        <div className="card-header py-3">
                          <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">
                              {page === 'info' && 'Informações Pessoais'}
                              {page === 'redes' && 'Redes Sociais'}
                            </h3>
                          </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Form*/}
                        <form className="form">
                          {/*begin::Body*/}
                          <div className="card-body">

                          </div>
                          {/*end::Body*/}
                        </form>
                        {/*end::Form*/}
                      </div>
                    </div>
                    {/*end::Content*/}
                  </div>
                  {/*end::Profile Personal Information*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}


            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalSee(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>


        </div>
        {/*end::Container*/}
      </div>
    </>
  );

}

export default withRouter(TransacoesGerenciar);                  