import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Api from '../../../../../services/api';
import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../../../../components/paginator';
import { Headerer } from '../../../../../components/headerer';

const UsuariosGerenciar = (register) => {
    const [loading, setLoading] = useState(false);
    const [buys, setBuys] = useState([]);
    const [actPage, setActPage] = useState();
    const [totPage, setTotPage] = useState();
    const [searchPost, setSearchPost] = useState({field: {}});
    const [changePageReferreds, setChangePageReferreds] = useState(null);

    useEffect(() => {
        setActPage(1);
        getBuys(1);
    }, [searchPost])

    useEffect(() => {
        if (changePageReferreds === null)
          return;
          getBuys(changePageReferreds);
    }, [changePageReferreds])
    
    function getBuys(page){
        setLoading(true);
        searchPost.post = register.register.id;
        
        Api.post("/postcomments/listcommentsuser?page=" + page, searchPost).then((rps) => {
          setLoading(false);
    
          if(rps.data.status!==true){
            Swal.fire({
              title: "Aviso!",
              icon: "warning",
              html: rps.data.message,
              showCloseButton: true,
              showCancelButton: false,
            });
            return;
          }
          setBuys(rps.data.obj);
          setTotPage(rps.data.last_page);
          setActPage(rps.data.current_page);
        })
      }

return (
    <>             
        <LoadingOverlay
            active={loading}
            spinner
            >
        

          <div className="row">
            <div className="col-md-12">

            <table className="table table-striped" id="kt_table_users">
                <thead className="bg-light-primary">
                <Headerer
                    columns={[
                    {name: 'ID', id: 'id', searchable: false, sortable: true},
                    {name: 'Comentário', id: 'text', searchable: false, sortable: true},
                    {name: 'Status', id: 'deleted', searchable: false, sortable: true},
                    ]}
                    setSearchOrig={setSearchPost}
                    searchOrig={searchPost}
                />
                </thead>

                <tbody>
                    {buys.map(tra => {
                        return (
                            <>
                            <tr>
                                <td className='text-center'>{tra.id}</td>
                                <td>{tra.text}</td>
                                <td className='text-center'>
                                {tra.deleted == "0" &&          
                                    <span class="label label-success label-inline mr-2">Disponível</span>}
                                {tra.deleted == "1" &&          
                                    <span class="label label-danger label-inline mr-2">Excluído</span>}
                                </td>
                            </tr>                    
                        </>
                        )
                    })}

                </tbody>
                </table>

            </div>
          </div>
        </LoadingOverlay>

      <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />
    </>
)
}

export default withRouter(UsuariosGerenciar); 