import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../../components/paginator';
import { Headerer } from '../../../components/headerer';
import { Link } from 'react-router-dom';

import InformacoesPessoais from "./parts/infopessoais";
import RedesSociais from "./parts/redessociais";
import Comentarios from "./parts/comentarios";
import Compras from './parts/compras';

import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Dashboard = () => {

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const {addToast} = useToasts();
  const [users, setUsers] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenSee, setIsOpenSee] = React.useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchUsers, setSearchUsers] = useState({field: {}});
  const [loadingModal, setLoadingModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [register, setRegister] = useState([]);
  const [conflict, setConflict] = useState([]);
  const [page, setPage] = useState('info');
  const [usersPhoto, setUsersPhoto] = useState([]);

  function dateFormat(data) {
    var dateUTC = new Date(data);
    var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    var dateBR = dateUTC.toLocaleString('pt-BR', options);
    return dateBR;
  }

  function getUsuarios(page) {
    setLoadingUsers(true);
    searchUsers.field.status = 'PENDING'
    Api.post("/users/listapproval?page=" + page, searchUsers).then((rps) => {
      setLoadingUsers(false);

      if(rps.data.status!==true){
        //Swal.fire(rps.data.message);
        //return;
      }
      setUsers(rps.data.data);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }
  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getUsuarios(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getUsuarios(1);
  }, [searchUsers])

  function closeModal() {
    setIsOpen(false);
  }

  //Para não gerar uma nova pesquisa, salvei em variavel local
  const [conflito, setConflitto] = useState(false);
  const [idade, setIdade] = useState();
  function openModal(user){
    setIsOpen(true);
    setLoadingModal(true);
    setIdade(user.age);

    Api.post("/users/listdocs", {id_user: user.id}).then((rps) => {
      setLoadingModal(false);

      setRegister(rps.data.user);
      setDocs(rps.data.docs);
      setUsersPhoto(rps.data.img);
    })

    if (user.status2 === 'CONFLITO') {
      Api.post("/users/listconflict", {tax_id: user.tax_id, nickname: user.nickname}).then((rps) => {
  
        setConflict(rps.data.rps);
        setConflitto(true);
      })
    } else {
      setConflitto(false);
    }
  }

    function openModalSee(user){
    setIsOpenSee(true);
    setLoadingModal(true);

    Api.post("/users/selectuser", {id_user: user.id}).then((rps) => {
      setLoadingModal(false);

      setRegister(rps.data.data);
    })
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModalSee() {
    setIsOpenSee(false);
  }

  function valDocs(id, usuario, status, text=null) {
    setLoadingModal(true);
    var post = {
      id_doc: id,
      id_user: usuario,
      new_status: status,
      text: text
    }
    Api.post("/users/updatedocs", post).then((rps) => {
      setLoadingModal(false);

      if(rps.data.status === true){
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }

      setDocs(rps.data.docs);

      if(rps.data.user !== 0){
        closeModal();
        setSearchUsers({field: {}});
      }      
    })
  }

  function denyDocument(id, usuario) {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Justificativa / Motivo',
      inputPlaceholder: 'Digite a Justificativa / Motivo...',
      inputAttributes: {
        'aria-label': 'Digite a Justificatica / Motivo'
      },
      showCancelButton: true,
      focus: false,
    }).then((result) => {
      valDocs(id, usuario, "DENIED", result.value)
    });
  }

  function bloqUser(user){

  }


  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Usuários</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          {/*begin::Item*/}
          <li class="breadcrumb-item">
            <a href="" class="text-muted">Aprovar Usuários</a>
          </li>
          {/*end::Item*/}
        </ul>     
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
     {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}

  {/*begin::Post*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">
      {/*begin::Card*/}
      <div className="card card-custom gutter-b example example-compact">
        {/*begin::Card header*/}
        {/*<div className="card-header">*/}
          {/*begin::Card title*/}
          
         
          {/*begin::Card toolbar*/}
        {/*</div>*/}
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body">

        <LoadingOverlay
      active={loadingUsers}
      spinner
      >
      <table className="table table-striped" id="kt_table_users">

        <thead className="bg-light-primary">
          <Headerer
            columns={[
              {name: 'ID', id: 'id', searchable: true, sortable: true},
              {name: 'Username', id: 'username', searchable: true, sortable: true},
              {name: 'Nome', id: 'name', searchable: true, sortable: true},
              {name: 'Tipo', id: 'type', searchable: false, sortable: true,
                selects: [
                  {name: "Produtor", value: 'PRODUCER'}, {name:"Usuário", value: 'USER'},
                  {name: "Admin", value: 'ADMIN'}
                ]},
              {name: 'Idade', id: 'age', searchable: false, sortable: false},
              {name: 'Status Prod', id: 'status', searchable: false, sortable: false},
              {name: 'Ação', id: 'action', searchable: false, sortable: false},
            ]}
            setSearchOrig={setSearchUsers}
            searchOrig={searchUsers}
          />
        </thead>

        <tbody>
          {users.map(user => {
            return (
              <>
                <tr>
                  <td className='text-center'>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.name}</td>
                  <td className='text-center'>
                    {user.type == "PRODUCER" && <>          
                      <span class="label label-danger label-inline mr-2">Produtor</span>
                    </>}
                    {user.type == "USER" && <>          
                      <span class="label label-primary label-inline mr-2">Usuário</span>
                    </>}
                    {user.type == "ADMIN" && <>          
                      <span class="label label-success label-inline mr-2">Admin</span>
                    </>}
                  </td>
                  <td className='text-center'>
                    {user.age < 18 ?          
                      <span class="label label-danger label-inline mr-2">{user.age}</span>
                    :         
                      <span class="label label-primary label-inline mr-2">{user.age}</span>}
                  </td>
                  <td className='text-center'>
                    {user.status == "PENDING" &&          
                      <span class="label label-warning label-inline mr-2">Pendente</span>}
                    {user.status == "APPROVED" &&          
                      <span class="label label-success label-inline mr-2">Aprovado</span>}
                    {user.status == "BLOCKED" &&           
                      <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                    {user.status == "DENIED" &&          
                      <span class="label label-dark label-inline mr-2">Reprovado</span>}
                    {user.status2 == "OK" ? 
                      <span class="label label-success label-inline mr-2">{user.status2}</span> :
                      <span class="label label-danger label-inline mr-2">{user.status2}</span>}
                  </td>
                  <td>
                    <div class="dropdown dropdown-inline dropleft">
                      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-more"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" onClick={e => { bloqUser(user) }}>Bloquear</a>
                        {user.status == "PENDING" && <>                       
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" onClick={ e => { openModal(user) } }>Validar Documentos</a>
                        </>}
                        {user.status == "APPROVED" && <>                       
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" onClick={ e => { openModalSee(user) } }>Ver Cadastro/Documentos</a>
                        </>}
                      </div>
                    </div>                    
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      </LoadingOverlay>

      <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingUsers} />
          
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  
  <Modal enforceFocus={false} size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
    <Modal.Header>
      <Modal.Title>Validar Documentos <h3 class="label label-warning label-inline font-size-h3 ml-20">Pendente</h3>
      {conflito === true && 
        <h3 class="label label-danger label-inline font-size-h3 ml-10">Conflito</h3>
      }
      </Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
        <i aria-hidden="true" className="flaticon2-cross" />
      </button>
    </Modal.Header>
    <LoadingOverlay
      active={loadingModal}
      spinner
      >
        <div className='row ml-3 mr-3 mt-3'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-2'>
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div className="symbol-label" style={{backgroundImage: "url("+usersPhoto+")"}} />
                </div>
              </div>
              <div className='col-md-10'>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label><b>Nome</b></label>
                    <input className="form-control" type="text" value={register.name} disabled />
                  </div>
                </div>
                  <div className='row'>
                    <div className='form-group col-md-4'>
                      <label><b>CPF</b></label>
                      <input className="form-control" type="text" value={register.tax_id} disabled />
                    </div>
                    <div className='form-group col-md-3'>
                      <label><b>Telefone</b></label>
                      <input className="form-control" type="text" value={register.phone} disabled />
                    </div>
                    <div className='form-group col-md-3'>
                      <label><b>Data Nascimento</b></label>
                      <input className="form-control" type="text" value={dateFormat(register.birth_date)} disabled />
                    </div>
                    <div className='form-group col-md-2'>
                      <label><b>Idade</b></label>
                      <input className="form-control" type="text" value={idade} disabled />
                    </div>
                  </div>
              </div>
            </div>           
          </div>
        </div>

        <ul className="nav nav-tabs ml-5 mr-5">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#documentos">
              <span className="nav-text">Documentos</span>
            </a>
          </li>
          {conflito === true && 
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#conflito">
                <span className="nav-text">Conflito</span>
              </a>
            </li>
          }
        </ul>

        <div className="modal-body">
          <div className="tab-content">

            {/*begin::Group - Documentos*/}
            <div className="tab-pane fade show active" id="documentos" role="tabpanel" aria-labelledby="documentos">
              <div className="col-md-12">
                <div className='row mt-3'>
                  <div className='col-md-6'><b>Foto</b></div>
                  <div className='col-md-2'><b>Documento</b></div>
                  <div className='col-md-2'><b>Status</b></div>
                  <div className='col-md-2'><b>Ação</b></div>
                </div>
                {docs.map(doc => {
                    return (
                      <>
                        <div className='row mt-3 mb-3'>
                          <div className='col-md-6'>
                            <img className="img-fluid img-thumbnail" src={doc.document} />
                          </div>
                          <div className='col-md-2'>
                            {doc.type == "cnh_frente" && <label>CNH Frente</label>}
                            {doc.type == "cnh_verso" && <label>CNH Verso</label>}
                            {doc.type == "selfie" && <label>Selfie</label>}
                          </div>
                          <div className='col-md-2'>
                            {doc.status == "PENDING" &&          
                              <span class="label label-warning label-inline mr-2">Pendente</span>}
                            {doc.status == "APPROVED" &&          
                              <span class="label label-success label-inline mr-2">Aprovado</span>}
                            {doc.status == "BLOCKED" &&           
                              <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                            {doc.status == "DENIED" &&          
                              <span class="label label-dark label-inline mr-2">Reprovado</span>}                      
                          </div>
                          <div className='col-md-2'>
                          { doc.status == "PENDING" && <>
                            <button type="button" class="btn btn-light-danger btn-circle btn-icon btn-sm m-2"
                                    onClick={e => { denyDocument(doc.id, doc.id_user) }}>
                              <i class="flaticon2-delete"></i>
                            </button>
                            <button type="button" class="btn btn-light-success btn-circle btn-icon btn-sm m-2"
                                    onClick={e => { valDocs(doc.id, doc.id_user, "APPROVED") }}>
                              <i class="flaticon2-check-mark"></i>  
                            </button>
                          </>}
                          </div>
                        </div>
                </>
                )
              })}
              </div>
            </div>
            {/*end::Group - Documentos*/}

            {/*begin::Group - Conflitos*/}
            <div className="tab-pane fade" id="conflito" role="tabpanel" aria-labelledby="conflito">
            <div className="col-md-12">
                <div className='row mt-3'>
                  <div className='col-md-1'><b>Código</b></div>
                  <div className='col-md-3'><b>Nome</b></div>
                  <div className='col-md-2'><b>Nickname</b></div>
                  <div className='col-md-2'><b>Documento</b></div>
                  <div className='col-md-2'><b>Dt. Nasc.</b></div>
                  <div className='col-md-1'><b>Status</b></div>
                </div>
                {conflict.map(con => {
                    return (
                      <>
                        <div className='row mt-3 mb-3'>
                          <div className='col-md-1'>{con.id}</div>
                          <div className='col-md-3'>{con.name}</div>
                          <div className='col-md-2'>{con.nickname}</div>
                          <div className='col-md-2'>{con.tax_id}</div>
                          <div className='col-md-2'>{dateFormat(con.birth_date)}</div>
                          <div className='col-md-1'>
                          {con.status == "PENDING" &&          
                            <span class="label label-warning label-inline mr-2">Pendente</span>}
                          {con.status == "APPROVED" &&          
                            <span class="label label-success label-inline mr-2">Aprovado</span>}
                          {con.status == "BLOCKED" &&           
                            <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                          {con.status == "DENIED" &&          
                            <span class="label label-dark label-inline mr-2">Reprovado</span>}
                          </div>
                        </div>
                </>
                )
              })}
              </div>       
            </div>
            {/*end::Group - Conflitos*/}

          </div>
        </div>
      


    <div className="row ml-3 mr-3">
      
    </div>
    </LoadingOverlay>
    <Modal.Footer>
      <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
    </Modal.Footer>
  </Modal>



  <Modal size={'xl'} centered show={modalIsOpenSee} onHide={() => closeModalSee()}>
    <Modal.Header>
      <Modal.Title>Ver Cadastro</Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalSee() }}>
        <i aria-hidden="true" className="flaticon2-cross" />
      </button>
    </Modal.Header>
    <LoadingOverlay
      active={loadingModal}
      spinner
      >
{/*begin::Entry*/}
<div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
  <div className="container">
    {/*begin::Profile Personal Information*/}
    <div className="d-flex flex-row mt-8 mb-8">
      {/*begin::Aside*/}
      <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-300px" id="kt_profile_aside">
        {/*begin::Profile Card*/}
        <div className="card card-custom card-stretch">
          {/*begin::Body*/}
          <div className="card-body">
            {/*begin::User*/}
            <div className="d-flex align-items-center">
              <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                <div className="symbol-label" style={{backgroundImage: 'url("assets/media/users/300_21.jpg")'}} />
              </div>
              <div>
                <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{register.name}</a>
                <div className="text-muted">{register.type}</div>
                <div className="mt-2">
                  {/*<a href="#" className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Chat</a>*/}
                </div>
              </div>
            </div>
            {/*end::User*/}
            {/*begin::Contact*/}
            <div className="py-9">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">E-mail:</span>
                <a href="#" className="text-muted text-hover-primary">{register.email}</a>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="font-weight-bold mr-2">Telefone:</span>
                <span className="text-muted">{register.phone}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="font-weight-bold mr-2">Localização:</span>
                <span className="text-muted">{register.city}/{register.state}</span>
              </div>
            </div>
            {/*end::Contact*/}
            {/*begin::Nav*/}
            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
              <div className="navi-item mb-2">
                <a onClick={e => { setPage('info'); }} className={`navi-link py-4 ${page === "info" ? " active" : ""}`}>
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                          <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">Informações Pessoais</span>
                </a>
              </div>
              <div className="navi-item mb-2">
                <a onClick={e => { setPage('redes'); }} className={`navi-link py-4 ${page === "redes" ? " active" : ""}`}>
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                          <rect x={0} y={0} width={24} height={24} />
                          <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                          <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">Redes Sociais</span>
                </a>
              </div>
              <div className="navi-item mb-2">
                <a onClick={e => { setPage('comentarios'); }} className={`navi-link py-4 ${page === "comentarios" ? " active" : ""}`}>
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M9.61764706,5 L8.73529412,7 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L9.61764706,5 Z M14.3823529,5 L21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L15.2647059,7 L14.3823529,5 Z M6.08823529,13 L5.20588235,15 L3,15 C2.44771525,15 2,14.5522847 2,14 C2,13.4477153 2.44771525,13 3,13 L6.08823529,13 Z M17.9117647,13 L21,13 C21.5522847,13 22,13.4477153 22,14 C22,14.5522847 21.5522847,15 21,15 L18.7941176,15 L17.9117647,13 Z M7.85294118,9 L6.97058824,11 L3,11 C2.44771525,11 2,10.5522847 2,10 C2,9.44771525 2.44771525,9 3,9 L7.85294118,9 Z M16.1470588,9 L21,9 C21.5522847,9 22,9.44771525 22,10 C22,10.5522847 21.5522847,11 21,11 L17.0294118,11 L16.1470588,9 Z M4.32352941,17 L3.44117647,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L4.32352941,17 Z M19.6764706,17 L21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L20.5588235,19 L19.6764706,17 Z" fill="#000000" opacity="0.3"/>
                            <path d="M11.044,5.256 L13.006,5.256 L18.5,19 L16,19 L14.716,15.084 L9.19,15.084 L7.5,19 L5,19 L11.044,5.256 Z M13.924,13.14 L11.962,7.956 L9.964,13.14 L13.924,13.14 Z" fill="#000000"/>
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">Comentários</span>
                </a>
              </div> 
              <div className="navi-item mb-2">
                <a onClick={e => { setPage('compras'); }} className={`navi-link py-4 ${page === "compras" ? " active" : ""}`}>
                  <span className="navi-icon mr-2">
                    <span className="svg-icon">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg*/}
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z" fill="#000000" opacity="0.3" transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "/>
                          <path d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z" fill="#000000"/>
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </span>
                  <span className="navi-text font-size-lg">Compras</span>
                </a>
              </div>   
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::Profile Card*/}
      </div>
      {/*end::Aside*/}
      {/*begin::Content*/}
      <div className="flex-row-fluid ml-lg-8">
        {/*begin::Card*/}
        <div className="card card-custom card-stretch">
          {/*begin::Header*/}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                { page === 'info' && 'Informações Pessoais' }
                { page === 'redes' && 'Redes Sociais' }
                { page === 'comentarios' && 'Comentários' }
                { page === 'compras' && 'Compras' }
              </h3>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Form*/}
          <form className="form">
            {/*begin::Body*/}
            <div className="card-body">
              { page === 'info' &&
                <InformacoesPessoais register={register} />
              }
              { page === 'redes' &&
                <RedesSociais register={register} />
              }
              { page === 'comentarios' &&
                <Comentarios register={register} />
              }
              { page === 'compras' &&
                <Compras register={register} />
              }
            </div>
            {/*end::Body*/}
          </form>
          {/*end::Form*/}
        </div>
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Profile Personal Information*/}
  </div>
  {/*end::Container*/}
</div>
{/*end::Entry*/}


    </LoadingOverlay>
    <Modal.Footer>
      <button type="button" onClick={e => { closeModalSee(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
    </Modal.Footer>
  </Modal>


    </div>
    {/*end::Container*/}
  </div>
    </>
  );

}

export default withRouter(Dashboard);                  