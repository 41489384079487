import classNames from 'classnames';
import { useEffect, useState } from 'react';

var timeoutChange = null;

export function Headerer({ columns, searchOrig, setSearchOrig, selects}) {
    //const [search, setSearch] = useState({field: {}});
    const [search, setSearch] = useState(searchOrig);

    useEffect(() => {
        clearInterval(timeoutChange);
        timeoutChange = setTimeout(function(){
            setSearchOrig(search);
        }, 600);
    }, [search]);

    return (
        <>
            <tr>
                {columns.map(column => {
                    return (
                        <>
                            <th className='text-center'>
                                {(column.sortable!==true) && <>
                                    {column.name}
                                    <label>&nbsp;</label>                                
                                </>}

                                {(column.sortable===true && search.order!==column.id) && 
                                    <label  onClick={e=> { setSearch({...search, order: column.id, order_by: 'desc'})}}>
                                        {column.name}
                                        <i class="ml-2 fas fa-sort text-primary"></i>
                                    </label>
                                }
                                {(search.order===column.id && search.order_by==='desc') && 
                                    <label  onClick={e=> { setSearch({...search, order_by: 'asc'})}}>
                                    {column.name}
                                    <i class="ml-2 fas fa-sort-up text-primary"></i>
                                </label>
                                }
                                {(search.order===column.id && search.order_by==='asc') && 
                                    <label  onClick={e=> { setSearch({...search, order_by: 'desc'})}}>
                                    {column.name}
                                    <i class="ml-2 fas fa-sort-down text-primary"></i>
                                </label>
                                }

                                <br/>

                                {column.searchable===true &&
                                    <input className='form-control' value={search.field[column.id]} onChange={e => { setSearch({...search, field: {...search.field, [column.id]: e.target.value}})}} />
                                }

                                {(column.selects!==null && column.selects!==undefined) && <>
                                    <select className='form-control' onChange={e => { setSearch({...search, field: {...search.field, [column.id]: e.target.value}})}}>
                                        <option value={''}></option>
                                        {column.selects.map(sel => {
                                            return (
                                                <option value={sel.value}>{sel.name}</option>
                                            )
                                        })}
                                    </select>
                                </>}

                                
                            </th>
                        </>
                    )
                })}
            </tr>
        </>
    )
}
