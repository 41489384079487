import React from 'react'
import Axios from 'axios';
import Swal from 'sweetalert2'
import qs from 'qs'; //TODO Verificar real necessidade. isso é QueryString
import withReactContent from 'sweetalert2-react-content'

const SwalRC = withReactContent(Swal)
const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json", // content-type accept for CodeIgniter
    //'Authorization' : 'Basic ' +  localStorage.getItem("hash") ? localStorage.getItem("hash") : 'null'
    'Authorization': localStorage.getItem("hash_session") ? "Bearer "+localStorage.getItem("hash_session") : 'null'
  }
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (!error.response) {
    alert('NETWORK ERROR')
  } else {
    const code = error.response.status
    const response = error.response.data
    const originalRequest = error.config;

    return new Promise((resolve, reject) => {
      if (code === 402) {
        Swal.fire({
          title: response.title!=null ? response.title : "Atenção",
          text: response.message,
          input: 'text',
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            const originalRequestConfig = error.config;
            originalRequestConfig.headers['X-2fa-token'] = result.value;
            resolve(api.request(originalRequestConfig));
          } else {
            reject();
          }
        });
      } else if(code===423) {
        Swal.fire("Ops!", "Você não tem permissão para acessar essa rota!");
        reject();
      } else {
        resolve(error.response);
      }
    });
  }
});

export default api;