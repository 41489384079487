import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";
import Select from 'react-select';

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Produtos = () => {

  function formata(val) {
    val = parseFloat(val);
    if (val > 0) {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
    return '0,00';
  }

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const {addToast} = useToasts();
  const [products, setProducts] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchProducts, setSearchProducts] = useState({field: {}});
  const [listCreator, setListCreator] = useState([]);
  const [formProducts, setFormProducts] = useState({});

  function getProducts(page) {
    setLoadingProducts(true);
    Api.post("/products/list?page=" + page, searchProducts).then((rps) => {
      setLoadingProducts(false);

      setProducts(rps.data.obj);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }

  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getProducts(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getProducts(1);
    getCreator();
  }, [searchProducts])

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(){
    setFormProducts({});
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function getCreator() {
    Api.get("/users/name").then(rps => {
      var user = rps.data.obj;
      var nUser = [];
      nUser[nUser.length] = { value: '', label: 'Selecione...' };
      user.forEach(e => {
        nUser[nUser.length] = { value: e.id, label: <span><img src={e.avatar} width="40px" style={{ height: "40px" }} /> {e.name} / {e.username} </span> };
      })
      setListCreator(nUser);
    })
    
  }

  function editProducts(sug){  
    
    var ca = JSON.parse(JSON.stringify(sug));
    ca.date_from = new Date(ca.date_from);
    ca.date_to = new Date(ca.date_to);
    console.log(sug);
    setFormProducts(ca);
    setIsOpen(true);            
  }

  function deleteProducts(sug){  
    var ca = JSON.parse(JSON.stringify(sug));

    setLoadingProducts(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja excluir a Sugestão de Criador?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('/creator/suggestion/delete', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getProducts();
          }

        })
      }
      setLoadingProducts(false);
    })          
  }

  function save() {
    if (!formProducts.id_user > 0) {
      formProducts.id_user = formProducts.id_userIncluir.value
    }
    
    Api.post("/creator/suggestion/save", formProducts).then((rps) => {

      if(rps.data.status === true){
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }
    })
    setIsOpen(false);
  }

  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Produtos</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          {/*begin::Item*/}
          <li class="breadcrumb-item">
            <a href="" class="text-muted">Gerenciar</a>
          </li>
          {/*end::Item*/}
        </ul>     
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
     {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
      </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}

  {/*begin::Post*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">
      {/*begin::Card*/}
      <div className="card card-custom gutter-b example example-compact">
        {/*begin::Card header*/}
        {/*<div className="card-header">*/}
          {/*begin::Card title*/}
          
         
          {/*begin::Card toolbar*/}
        {/*</div>*/}
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body">

        <LoadingOverlay
          active={loadingProducts}
          spinner
        >
      <table className="table table-striped" id="kt_table_users">

        <thead className="bg-light-primary">
          <Headerer
            columns={[
              {name: 'Id', id: 'products.id', searchable:true, sortable: true},
              {name: 'Username', id: 'users.username', searchable: true, sortable: true},
              {name: 'Produto', id: 'products.name', searchable: true, sortable: true},
              {name: 'Descrição', id: 'products.description', searchable: true, sortable: true},
              {name: 'Disponível de', id: 'products.availabled_at', searchable: true, sortable: true},
              {name: 'Disponível até', id: 'products.availabled_until', searchable: true, sortable: true},
              {name: 'Valor', id: 'products.price', searchable: true, sortable: true},
              {name: 'Ação', id: 'action', searchable: false, sortable: false},
            ]}
            setSearchOrig={setSearchProducts}
            searchOrig={searchProducts}
          />
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {products.map(pro => {
            return (
              <>
                <tr>
                  <td className='text-center'>{pro.id}</td>
                  <td>{pro.username}</td>
                  <td>{pro.name}</td>
                  <td>{pro.description}</td>
                  <td className='text-center'>{pro.availabled_at}</td>
                  <td className='text-center'>{pro.availabled_until}</td>
                  <td className='text-center'>{formata(pro.price)}</td>
                  <td>
                    <div class="dropdown dropdown-inline dropleft">
                      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-more"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" onClick={e => { deleteProducts(pro) }}>Apagar</a>
                      </div>
                    </div>                    
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      </LoadingOverlay>

      <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingProducts} />
          
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  
  <Modal size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
    <Modal.Header>
      <Modal.Title>Produtos</Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
        <i aria-hidden="true" className="flaticon2-cross" />
      </button>
    </Modal.Header>

    <div className="row ml-3 mr-3 mt-3">
      <div className="form-group col-md-12">
        <label>Username (Produtor)<span class="text-danger">*</span></label>
        <Select
          options={listCreator}
          placeholder="Selecione..."
          defaultValue={listCreator.find(
            x => x.value === formProducts?.id_user
          )}
          onChange={(e) => {setFormProducts({...formProducts, id_userIncluir: e})}}
        />
      </div>

    </div>

    <Modal.Footer>
      <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
      <button type="button" onClick={e => { save(); }} className="btn btn-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-save" />Salvar</button>
    </Modal.Footer>
  </Modal>

    </div>
    {/*end::Container*/}
  </div>
    </>
  );

}

export default withRouter(Produtos);                  