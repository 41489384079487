import React, { useEffect } from "react";
import { withRouter } from "react-router";

const Dashboard = (register) => {

    useEffect(() => {
        console.log(register);
    }, [register])
return (
    <>             
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Telefone</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <div className="input-group-prepend">
                <span className="input-group-text">
                <i className="la la-phone" />
                </span>
            </div>
            <input type="text" className="form-control form-control-lg form-control-solid" value={register.phone} placeholder="Phone" />
            </div>
        </div>
        </div>
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">E-mail</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <div className="input-group-prepend">
                <span className="input-group-text">
                <i className="la la-at" />
                </span>
            </div>
            <input type="text" className="form-control form-control-lg form-control-solid" value={register.email} placeholder="Email" />
            </div>
        </div>
        </div>
        <div className="form-group row">
        <label className="col-xl-3 col-lg-3 col-form-label">Site</label>
        <div className="col-lg-9 col-xl-9">
            <div className="input-group input-group-lg input-group-solid">
            <input type="text" className="form-control form-control-lg form-control-solid" value={register.site} />
            </div>
        </div>
        </div>
    </>
)
}

export default withRouter(Dashboard); 