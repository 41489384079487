import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

const Publicacoes = () => {

  function dateFormatBr(date) {
    let data = new Date(date);
    let dataFormatada = (format_two_digits(data.getDate())) + "/" +
      (format_two_digits(data.getMonth())) + "/" +
      (format_two_digits(data.getFullYear())) + " " +
      (format_two_digits(data.getHours())) + ":" +
      (format_two_digits(data.getMinutes())) + ":" +
      (format_two_digits(data.getSeconds()));
    return dataFormatada;
  }

  function format_two_digits(n) {
    return n < 10 ? '0' + n : n;
  }

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const { addToast } = useToasts();
  const [posts, setPosts] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpenComments, setIsOpenComments] = React.useState(false);
  const [modalIsOpenBuys, setIsOpenBuys] = React.useState(false);
  const [modalIsOpenMedia, setIsOpenMedia] = React.useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [searchPost, setSearchPost] = useState({ field: {} });
  const [loadingModal, setLoadingModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [buys, setBuys] = useState([]);
  const [media, setMedia] = useState([]);

  function getPublicacoes(page) {
    setLoadingPost(true);
    Api.post("/posts/list?page=" + page, searchPost).then((rps) => {
      setLoadingPost(false);

      setPosts(rps.data.obj);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }

  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getPublicacoes(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getPublicacoes(1);
  }, [searchPost])

  function closeModalComments() {
    setIsOpenComments(false);
  }

  function openModalComments(post) {
    setIsOpenComments(true);
    setLoadingModal(true);

    Api.post("/postcomments/listcomments", { post: post.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        Swal.fire({
          title: "Aviso!",
          icon: "warning",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
        setIsOpenComments(false);
        return;
      }
      setComments(rps.data.obj);
    })
  }

  function openModalBuys(post) {
    setIsOpenBuys(true);
    setLoadingModal(true);

    Api.post("/postbuys/listbuys", { post: post.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        Swal.fire({
          title: "Aviso!",
          icon: "warning",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
        setIsOpenBuys(false);
        return;
      }
      setBuys(rps.data.obj);
    })
  }

  function closeModalBuys() {
    setIsOpenBuys(false);
  }

  function openModalMedia(post) {
    setIsOpenMedia(true);
    setLoadingModal(true);

    Api.post("/postmedia/listmedia", { post: post.id }).then((rps) => {
      setLoadingModal(false);

      if (rps.data.status !== true) {
        Swal.fire({
          title: "Aviso!",
          icon: "warning",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
        setIsOpenMedia(false);
        return;
      }
      setMedia(rps.data.obj);
    })
  }

  function closeModalMedia() {
    setIsOpenMedia(false);
  }

  function bloqUser(id) {
    Swal.fire({
      input: 'textarea',
      inputLabel: 'Justificativa / Motivo',
      inputPlaceholder: 'Digite a Justificativa / Motivo...',
      inputAttributes: {
        'aria-label': 'Digite a Justificatica / Motivo'
      },
      showCancelButton: true
    });
  }

  function reprocessar(media) {
    Api.post("/postmedia/reprocess", { id_post_media: media.id }).then((rps) => {
      Swal.fire(rps.data.message);
      console.log(rps.data);
    })
  }


  function deleteError(post) {
    Api.post("/postmedia/deleteError", { id_post: post.id }).then((rps) => {
      Swal.fire(rps.data.message);
      console.log(rps.data);
    })
  }


  return (
    <>
      {/*begin::Toolbar*/}
      <div className="toolbar" id="kt_toolbar">
        {/*begin::Container*/}
        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
          {/*begin::Page title*/}
          <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            {/*begin::Title*/}
            <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Publicações</h1>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <span className="h-20px border-gray-300 border-start mx-4" />
            {/*end::Separator*/}
            {/*begin::Breadcrumb*/}
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
              {/*begin::Item*/}
              <li class="breadcrumb-item">
                <a href="" class="text-muted">Gerenciar Publicações</a>
              </li>
              {/*end::Item*/}
            </ul>
            {/*end::Breadcrumb*/}
          </div>
          {/*end::Page title*/}
          {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Toolbar*/}

      {/*begin::Post*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b example example-compact">
            {/*begin::Card header*/}
            {/*<div className="card-header">*/}
            {/*begin::Card title*/}


            {/*begin::Card toolbar*/}
            {/*</div>*/}
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body">

              <LoadingOverlay
                active={loadingPost}
                spinner
              >
                <table className="table table-striped" id="kt_table_users">

                  <thead className="bg-light-primary">
                    <Headerer
                      columns={[
                        { name: 'ID', id: 'posts.id', searchable: true, sortable: true },
                        { name: 'Descrição', id: 'posts.post', searchable: true, sortable: true },
                        {
                          name: 'Conteúdo', id: 'posts.type', searchable: false, sortable: false,
                          selects: [
                            { name: "Foto", value: 'MEDIA' }, { name: "Live", value: 'LIVE' },
                            { name: "Stories", value: 'STORY' }
                          ]
                        },
                        { name: 'Criador', id: 'posts.id_user', searchable: true, sortable: true },
                        { name: 'Comen.', id: 'posts.comments_count', searchable: true, sortable: true },
                        { name: 'Likes', id: 'posts.likes_count', searchable: true, sortable: true },
                        { name: 'Data', id: 'posts.available_at', searchable: true, sortable: true },
                        {
                          name: 'Agendado', id: 'posts.is_available', searchable: false, sortable: false,
                          selects: [
                            { name: "Sim", value: 0 }, { name: "Não", value: 1 }
                          ]
                        },
                        {
                          name: 'Status', id: 'posts.status', searchable: false, sortable: true,
                          selects: [
                            { name: "Disponível", value: 'AVAILABLE' }, { name: "Erro", value: 'ERROR' },
                            { name: "Processando", value: 'PROCESSING' }
                          ]
                        },
                        { name: 'Ação', id: 'action', searchable: false, sortable: false },
                      ]}
                      setSearchOrig={setSearchPost}
                      searchOrig={searchPost}
                    />
                  </thead>

                  <tbody>
                    {posts.map(post => {
                      return (
                        <>
                          <tr>
                            <td className='text-center'>{post.id}</td>
                            <td>{post.post}</td>
                            <td className='text-center'>
                              {post.type == "MEDIA" && <>
                                <i class="fas fa-photo-video text-dark"></i>
                              </>}
                              {post.type == "LIVE" && <>
                                LIVE
                              </>}
                              {post.type == "STORY" && <>
                                <i class="fas fa-fire text-dark"></i>
                              </>}
                            </td>
                            <td><small>ID User: <b>{post.id_user}</b></small><br />{post.username}</td>
                            <td className='text-center'>{post.comments_count}</td>
                            <td className='text-center'>{post.likes_count}</td>
                            <td className='text-center'>{dateFormatBr(post.available_at)}</td>
                            <td className='text-center'>{post.is_available == "0" &&
                              <><span class="label label-success label-inline mr-2">Sim</span><br /><small>{dateFormatBr(post.available_at)}</small></>}
                              {post.is_available == "1" &&
                                <span class="label label-secondary label-inline mr-2">Não</span>}
                            </td>
                            <td className='text-center'>
                              {post.status == "AVAILABLE" &&
                                <span class="label label-success label-inline mr-2">Disponível</span>}
                              {post.status == "PROCESSING" &&
                                <span class="label label-primary label-inline mr-2">Processando</span>}
                              {post.status == "ERROR" &&
                                <span class="label label-danger label-inline mr-2">Erro</span>}
                              {(post.deleted === 1 || post.deleted === true) && <small>DELETED</small>}
                            </td>
                            <td>
                              <div class="dropdown dropdown-inline dropleft">
                                <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="flaticon-more"></i>
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" onClick={e => { bloqUser(post) }}>Reprovar</a>
                                  <div class="dropdown-divider"></div>
                                  <a class="dropdown-item" onClick={e => { openModalComments(post) }}>Ver Comentários</a>
                                  <a class="dropdown-item" onClick={e => { openModalBuys(post) }}>Ver Compras</a>
                                  <a class="dropdown-item" onClick={e => { openModalMedia(post) }}>Ver Mídia</a>
                                  <a class="dropdown-item" onClick={e => { deleteError(post) }}>Excluir por erro</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </LoadingOverlay>

              <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingPost} />

            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}

          {/*end::Post*/}

          <Modal size={'lg'} centered show={modalIsOpenComments} onHide={() => closeModalComments()}>
            <Modal.Header>
              <Modal.Title>Comentários</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalComments() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              <div className="row ml-3 mr-3">
                <div className="col-md-12">

                  <table className="table table-striped" id="kt_table_users">
                    <thead className="bg-light-primary">
                      <tr>
                        <th className='col-md-2 pl-3 text-center'>ID</th>
                        <th className='col-md-3 pl-3'>Usuário</th>
                        <th className='col-md-4 pl-3'>Texto</th>
                        <th className='col-md-2 pl-3'>Status</th>
                        <th className='col-md-1 pl-3 text-center'>Ação</th>
                      </tr>
                    </thead>
                    <tbody>

                      {comments.map(com => {
                        return (
                          <>
                            <tr>
                              <td className='text-center'>{com.id}</td>
                              <td>{com.username}</td>
                              <td>{com.text}</td>
                              <td>
                                {com.deleted == "0" &&
                                  <span class="label label-success label-inline mr-2">Disponível</span>}
                                {com.deleted == "1" &&
                                  <span class="label label-danger label-inline mr-2">Excluído</span>}
                              </td>
                              <td></td>
                            </tr>
                          </>
                        )
                      })}

                    </tbody>
                  </table>

                </div>
              </div>
            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalComments(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>



          <Modal size={'lg'} centered show={modalIsOpenBuys} onHide={() => closeModalBuys()}>
            <Modal.Header>
              <Modal.Title>Compras</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalBuys() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              <div className="row p-3">
                <div className="col-md-12">

                  <table className="table table-striped" id="kt_table_users">
                    <thead className="bg-light-primary">
                      <tr>
                        <th className='col-md-2 pl-3 text-center'>ID</th>
                        <th className='col-md-6 pl-3'>Comprador</th>
                        <th className='col-md-2 pl-3'>Valor</th>
                        <th className='col-md-2 pl-3 text-center'>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      {buys.map(buy => {
                        return (
                          <>
                            <tr>
                              <td className='col-md-2 pl-3 text-center'>{buy.id}</td>
                              <td className='col-md-6 pl-3'>{buy.username}</td>
                              <td className='col-md-2 pl-3'>{buy.price}</td>
                              <td className='col-md-2 pl-3 text-center'>
                                {buy.status === "ACTIVE" &&
                                  <span class="label label-success label-inline mr-2">Ativo</span>}
                                {buy.status === "CANCELLED" &&
                                  <span class="label label-danger label-inline mr-2">Cancelado</span>}
                                {buy.status === "BLOCKED" &&
                                  <span class="label label-dark label-inline mr-2">Bloqueado</span>}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>

                </div>
              </div>

            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalBuys(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>


          <Modal size={'lg'} centered show={modalIsOpenMedia} onHide={() => closeModalMedia()}>
            <Modal.Header>
              <Modal.Title>Mídias</Modal.Title>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModalMedia() }}>
                <i aria-hidden="true" className="flaticon2-cross" />
              </button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingModal}
              spinner
            >
              <div className="row p-3">
                <div className="col-md-12">

                  <table className="table table-striped" id="kt_table_users">
                    <thead className="bg-light-primary">
                      <tr>
                        <th className='col-md-1 pl-3 text-center'>Type</th>
                        <th className='col-md-3 pl-3 text-center'>Status</th>
                        <th className='col-md-1 pl-3 text-center'>Step</th>
                        <th className='col-md-1 pl-3 text-center'>Encoder</th>
                        <th className='col-md-3 pl-3 text-center'>Data Criação</th>
                        <th className='col-md-3 pl-3 text-center'>Data Atualização</th>
                      </tr>
                    </thead>
                    <tbody>

                      {media.map(med => {
                        return (
                          <>
                            <tr>
                              <td className='pl-3 text-center'>
                                {med.type == "PHOTO" && <>
                                  <i class="fas fa-photo-video text-dark"></i> {med.type}
                                </>}
                                {med.type == "VIDEO" && <>
                                  <i class="fas fa-video text-dark"></i> {med.type}
                                </>}
                                {med.type == "AUDIO" && <>
                                  <i class="fas fa-microphone text-dark"></i> {med.type}
                                </>}
                              </td>
                              <td className='pl-3 text-center'>
                                {med.status == "AVAILABLE" &&
                                  <span class="label label-success label-inline mr-2">{med.status}</span>}
                                {med.status == "PROCESSING" &&
                                  <span class="label label-primary label-inline mr-2">{med.status}</span>
                                }
                                {med.status == "ERROR" &&
                                  <span class="label label-danger label-inline mr-2">{med.status}</span>
                                }
                                <button className="btn btn-sm btn-secondary m-1" onClick={e => { e.preventDefault(); reprocessar(med); }}><i class="fas fa-redo text-dark"></i></button>
                              </td>
                              <td className='pl-3 text-center'>
                                {med.step}
                              </td>
                              <td className='pl-3 text-center'>
                                {med.encoder}
                              </td>
                              <td className='pl-3 text-center'>
                                {dateFormatBr(med.created_at)}
                              </td>
                              <td className='pl-3 text-center'>
                                {dateFormatBr(med.updated_at)}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>

                </div>
              </div>

            </LoadingOverlay>
            <Modal.Footer>
              <button type="button" onClick={e => { closeModalMedia(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
            </Modal.Footer>
          </Modal>



        </div>
        {/*end::Container*/}
      </div>
    </>
  );

}

export default withRouter(Publicacoes);                  