import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';

import Api from '../../services/api';
import LayoutInst from '../../layout/index_inst.jsx';


const Login = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [frmLogin, setFrmLogin] = useState({ email: '', password: '' });

  useEffect(() => {
    //document.title = "Login - 2Close"
  }, []);



  function doLogin() {
    Api.post("/auth/login", frmLogin).then((rps) => {
      console.log(rps);
      if (rps.data.status === true) {
        var token = rps.data.token;
        localStorage.setItem('hash_session', token)
        localStorage.setItem('user', rps.data.user)
        Api.defaults.headers['Authorization'] = "Bearer "+token
        history.push("/dashboard");
      } else {
        addToast("Ocorreu um erro", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });



        

        /*.then( e => {
            console.log("a");
        })*/


      }
    })
  }



  return (
    <>

      {/* Content*/}
      <div className="d-flex flex-column flex-root">
        <div className="row h-full">
          

          <div className="col-md-6 center-vertical hidden-mobile" style={{ backgroundColor: '#328BF1' }}>
                <img alt="Logo" src="assets/images/logo-bg.png" className="logo-login mx-auto d-block" />
          </div>




          <div className="d-flex col-md-6 col-sm-12">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-500px p-10 p-lg-15 mx-auto">

              <div className="full-width only-mobile">
              <img alt="Logo" src="assets/images/logo/logo-horizontal.png" className="logo-login-2 mx-auto" />
              </div>

                  <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Usuário</label>
                    <input
                      value={frmLogin.email}
                      onChange={(e) => { setFrmLogin({ ...frmLogin, email: e.target.value }) }}
                      className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" />
                  </div>
                  <div className="fv-row mb-10">
                    <div className="d-flex flex-stack mb-2">
                      <label className="form-label fw-bolder text-dark fs-6 mb-0">Senha</label>
                    </div>
                    <input
                      value={frmLogin.password}
                      onChange={(e) => { setFrmLogin({ ...frmLogin, password: e.target.value }) }} className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" />
                  </div>
                  <div className="text-center">
                    <button onClick={e => { e.preventDefault(); doLogin(); }} type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5 fw-bolder">
                      <span className="indicator-label">Acessar</span>
                      <span className="indicator-progress">Aguarde...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}



export default withRouter(Login);                  