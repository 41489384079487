import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

const AcademicoCards = () => {


  useEffect(() => {
    //document.title = ""
  }, []);


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);


  function handleFechaModal() {
    setIsOpen(false);
  }

  function abreModal(){
    console.log("abre model");
    setIsOpen(true);
  }


  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Gerenciar Usuários</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          {/*begin::Item*/}
          <li className="breadcrumb-item text-muted">
            <a href="../../demo1/dist/index.html" className="text-muted text-hover-primary">Dashboard</a>
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="breadcrumb-item">
            <span className="bullet bg-gray-300 w-5px h-2px" />
          </li>
          {/*end::Item*/}
          {/*begin::Item*/}
          <li className="breadcrumb-item text-muted">Usuários</li>
          {/*end::Item*/}
          <li className="breadcrumb-item">
            <span className="bullet bg-gray-300 w-5px h-2px" />
          </li>
          {/*begin::Item*/}
          <li className="breadcrumb-item text-dark">Gerenciar Usuários</li>
          {/*end::Item*/}
        </ul>
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
      <p>
        <DateRangePicker
        showToolbar
        showTodayButton
        openTo={"month"}
        showDaysOutsideCurrentMonth
        startText="Check-in"
        endText="Check-out"
        onChange={(date) => console.log(date)}
        
      />
      </p>


      {/*begin::Actions*/}
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        {/*begin::Filter menu*/}
        <div className="m-0">
          {/*begin::Menu toggle*/}
          <a href="#" className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
            {/*begin::Svg Icon | path: icons/duotune/general/gen031.svg*/}
            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}Filter</a>
          {/*end::Menu toggle*/}
          {/*begin::Menu 1*/}
          <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_624475ed0ca19">
            {/*begin::Header*/}
            <div className="px-7 py-5">
              <div className="fs-5 text-dark fw-bolder">Filter Options</div>
            </div>
            {/*end::Header*/}
            {/*begin::Menu separator*/}
            <div className="separator border-gray-200" />
            {/*end::Menu separator*/}
            {/*begin::Form*/}
            <div className="px-7 py-5">
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Status:</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <div>
                  <select className="form-select form-select-solid select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_624475ed0ca19" data-allow-clear="true" data-select2-id="select2-data-7-4hkv" tabIndex={-1} aria-hidden="true">
                    <option data-select2-id="select2-data-9-rbwy" />
                    <option value={1}>Approved</option>
                    <option value={2}>Pending</option>
                    <option value={2}>In Process</option>
                    <option value={2}>Rejected</option>
                  </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-8-f9om" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-fr0s-container" aria-controls="select2-fr0s-container"><span className="select2-selection__rendered" id="select2-fr0s-container" role="textbox" aria-readonly="true" title="Select option"><span className="select2-selection__placeholder">Select option</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                </div>
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Member Type:</label>
                {/*end::Label*/}
                {/*begin::Options*/}
                <div className="d-flex">
                  {/*begin::Options*/}
                  <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input className="form-check-input" type="checkbox" defaultValue={1} />
                    <span className="form-check-label">Author</span>
                  </label>
                  {/*end::Options*/}
                  {/*begin::Options*/}
                  <label className="form-check form-check-sm form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" defaultValue={2} defaultChecked="checked" />
                    <span className="form-check-label">Customer</span>
                  </label>
                  {/*end::Options*/}
                </div>
                {/*end::Options*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="mb-10">
                {/*begin::Label*/}
                <label className="form-label fw-bold">Notifications:</label>
                {/*end::Label*/}
                {/*begin::Switch*/}
                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" defaultValue name="notifications" defaultChecked="checked" />
                  <label className="form-check-label">Enabled</label>
                </div>
                {/*end::Switch*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Actions*/}
              <div className="d-flex justify-content-end">
                <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Menu 1*/}
        </div>
        {/*end::Filter menu*/}
        {/*begin::Secondary button*/}
        {/*end::Secondary button*/}
        {/*begin::Primary button*/}
        <a href="../../demo1/dist/.html" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Create</a>
        {/*end::Primary button*/}
      </div>
      {/*end::Actions*/}
    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}
  {/*begin::Post*/}
  <div className="post d-flex flex-column-fluid" id="kt_post">
    {/*begin::Container*/}
    <div id="kt_content_container" className="container-xxl">
      {/*begin::Card*/}
      <div className="card">
        {/*begin::Card header*/}
        <div className="card-header border-0 pt-6">
          {/*begin::Card title*/}
          <div className="card-title">
            {/*begin::Search*/}
            <div className="d-flex align-items-center position-relative my-1">
              {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height={2} rx={1} transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                </svg>
              </span>
              {/*end::Svg Icon*/}
              <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Buscar usuário" />
            </div>
            {/*end::Search*/}
          </div>
          {/*begin::Card title*/}
          {/*begin::Card toolbar*/}
          <div className="card-toolbar">
            {/*begin::Toolbar*/}
            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
              {/*begin::Filter*/}
              {/*end::Filter*/}
              {/*begin::Export*/}
              <button type="button" className="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_export_users">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr078.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="12.75" y="4.25" width={12} height={2} rx={1} transform="rotate(90 12.75 4.25)" fill="currentColor" />
                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor" />
                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Exportar XLS</button>
              {/*end::Export*/}
              {/*begin::Add user*/}
              <button onClick={(e)=>{ abreModal() }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_user">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="11.364" y="20.364" width={16} height={2} rx={1} transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                    <rect x="4.36396" y="11.364" width={16} height={2} rx={1} fill="currentColor" />
                  </svg>
                </span>
                {/*end::Svg Icon*/}Add User</button>
              {/*end::Add user*/}
            </div>
            {/*end::Toolbar*/}
            {/*begin::Group actions*/}
            <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
              <div className="fw-bolder me-5">
                <span className="me-2" data-kt-user-table-select="selected_count" />Selected</div>
              <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
            </div>
            {/*end::Group actions*/}
          </div>
          {/*end::Card toolbar*/}
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body py-4">
          {/*begin::Table*/}
          <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                {/*begin::Table head*/}
                <thead>
                  {/*begin::Table row*/}
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"><th className="w-10px pe-2 sorting_disabled" rowSpan={1} colSpan={1} style={{width: '29.25px'}} aria-label="
													">
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" defaultValue={1} />
                      </div>
                    </th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '228.3px'}} aria-label="User: activate to sort column ascending">Usuário</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Role: activate to sort column ascending">Plano</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Last login: activate to sort column ascending">Status</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '126.017px'}} aria-label="Last login: activate to sort column ascending">Último Login</th>
                    <th className="min-w-125px sorting" tabIndex={0} aria-controls="kt_table_users" rowSpan={1} colSpan={1} style={{width: '164.083px'}} aria-label="Joined Date: activate to sort column ascending">Cadastro</th>
                    <th className="text-end min-w-100px sorting_disabled" rowSpan={1} colSpan={1} style={{width: '100.817px'}} aria-label="Actions">Ações</th></tr>
                  {/*end::Table row*/}
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody className="text-gray-600 fw-bold">
                  <tr className="odd">
                    {/*begin::Checkbox*/}
                    <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" defaultValue={1} />
                      </div>
                    </td>
                    {/*end::Checkbox*/}
                    {/*begin::User=*/}
                    <td className="d-flex align-items-center">
                      {/*begin:: Avatar */}
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <a href="../../demo1/dist/apps/user-management/users/view.html">
                          <div className="symbol-label">
                            <img src="assets/images/daniel.jpg" alt="Daniel" className="w-100" />
                          </div>
                        </a>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::User details*/}
                      <div className="d-flex flex-column">
                        <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Emma Smith</a>
                        <span>daniel.poltronieri@juridika.app</span>
                      </div>
                      {/*begin::User details*/}
                    </td>
                    {/*end::User=*/}
                    {/*begin::Role=*/}
                    <td>
                    <div className="badge badge-light fw-bolder">Free</div>
                    </td>
                    {/*end::Role=*/}
                    {/*begin::Last login=*/}
                    <td data-order="2022-05-18T18:13:25-03:00">
                      <div className="badge badge-light fw-bolder">Não Verificado</div>
                    </td>
                    {/*end::Last login=*/}
                    <td>
                      <div className="badge badge-light fw-bolder">Ontem</div>
                    </td>
                    {/*end::Two step=*/}
                    {/*begin::Joined*/}
                    <td data-order="2022-07-25T18:05:00-03:00">
                      <div className="badge badge-light fw-bolder">22/05/2022 - 12:22:51</div>
                    </td>
                    {/*begin::Joined*/}
                    {/*begin::Action=*/}
                    <td className="text-end">
                      <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Gerenciar
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                        <span className="svg-icon svg-icon-5 m-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}</a>
                    </td>
                    {/*end::Action=*/}
                  </tr>
                  <tr className="even">
                    {/*begin::Checkbox*/}
                    <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" defaultValue={1} />
                      </div>
                    </td>
                    {/*end::Checkbox*/}
                    {/*begin::User=*/}
                    <td className="d-flex align-items-center">
                      {/*begin:: Avatar */}
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <a href="../../demo1/dist/apps/user-management/users/view.html">
                          <div className="symbol-label">
                            <img src="assets/images/daniel.jpg" alt="Daniel" className="w-100" />
                          </div>
                        </a>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::User details*/}
                      <div className="d-flex flex-column">
                        <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Melody Macy</a>
                        <span>melody@altbox.com</span>
                      </div>
                      {/*begin::User details*/}
                    </td>
                    {/*end::User=*/}
                    {/*begin::Role=*/}
                    <td>
                    <div className="badge badge-light-info fw-bolder">Assinante</div>
                    </td>
                    {/*end::Role=*/}
                    {/*begin::Last login=*/}
                    <td data-order="2022-05-19T17:53:25-03:00">
                      <div className="badge badge-light-success fw-bolder">Verificado</div>
                    </td>
                    {/*end::Last login=*/}
                    {/*begin::Two step=*/}
                    <td>
                      <div className="badge badge-light fw-bolder">Ontem</div>
                    </td>
                    {/*end::Two step=*/}
                    {/*begin::Joined*/}
                    <td data-order="2022-02-21T10:30:00-03:00">
                    <div className="badge badge-light fw-bolder">22/05/2022 - 12:22:51</div>
                    </td>
                    {/*begin::Joined*/}
                    {/*begin::Action=*/}
                    <td className="text-end">
                      <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Gerenciar
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                        <span className="svg-icon svg-icon-5 m-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}</a>
                      {/*begin::Menu*/}
                      <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu*/}
                    </td>
                    {/*end::Action=*/}
                  </tr>
                  </tbody>
                {/*end::Table body*/}
              </table>
              </div>
              
              <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                    <ul className="pagination">
                      <li className="paginate_button page-item previous disabled" id="kt_table_users_previous">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={0} tabIndex={0} className="page-link">
                          <i className="previous" />
                        </a>
                      </li>
                      <li className="paginate_button page-item active">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={3} tabIndex={0} className="page-link">3</a>
                      </li>
                      <li className="paginate_button page-item next" id="kt_table_users_next">
                        <a href="#" aria-controls="kt_table_users" data-dt-idx={4} tabIndex={0} className="page-link">
                          <i className="next" /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          {/*end::Table*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  <Modal
        size={"xl"}
        show={modalIsOpen}
        onHide={() => handleFechaModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Variações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
asdasd
          </Modal.Body>
          </Modal>



          </div>
    {/*end::Container*/}
  </div>
    </>
  );

}



export default withRouter(AcademicoCards);                  