import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";
import Select from 'react-select';

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const TransacoesGerenciar = () => {

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const {addToast} = useToasts();
  const [suggestion, setSuggestion] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [searchSuggestion, setSearchSuggestion] = useState({field: {}});
  const [listCreator, setListCreator] = useState([]);
  const [formSuggestion, setFormSuggestion] = useState({});

  function getSuggestion(page) {
    setLoadingSuggestion(true);
    Api.post("/creator/suggestion/list?page=" + page, searchSuggestion).then((rps) => {
      setLoadingSuggestion(false);

      if(rps.data.status!==true){
        //Swal.fire(rps.data.message);
        //return;
      }
      setSuggestion(rps.data.obj);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }

  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getSuggestion(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getSuggestion(1);
    getCreator();
  }, [searchSuggestion])

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(){
    setFormSuggestion({});
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function getCreator() {
    Api.get("/users/name").then(rps => {
      var user = rps.data.obj;
      var nUser = [];
      nUser[nUser.length] = { value: '', label: 'Selecione...' };
      user.forEach(e => {
        //<span><img src={e.avatar} width="40px" style={{ height: "40px" }} /> `${e.name} / ${e.username}` </span>
        nUser[nUser.length] = { value: e.id, label: `${e.name} / ${e.username}` };
      })
      setListCreator(nUser);
    })
    
  }

  function editSuggestion(sug){  
    
    var ca = JSON.parse(JSON.stringify(sug));
    ca.date_from = new Date(ca.date_from);
    ca.date_to = new Date(ca.date_to);
    console.log(sug);
    setFormSuggestion(ca);
    setIsOpen(true);            
  }

  function deleteSuggestion(sug){  
    var ca = JSON.parse(JSON.stringify(sug));

    setLoadingSuggestion(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja excluir a Sugestão de Criador?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('/creator/suggestion/delete', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getSuggestion();
          }

        })
      }
      setLoadingSuggestion(false);
    })          
  }

  function save() {
    if (!formSuggestion.id_user > 0) {
      formSuggestion.id_user = formSuggestion.id_userIncluir.value
    }
    
    Api.post("/creator/suggestion/save", formSuggestion).then((rps) => {

      if(rps.data.status === true){
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }
    })
    setIsOpen(false);
  }

  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Sugestão de Criadores</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          {/*begin::Item*/}
          <li class="breadcrumb-item">
            <a href="" class="text-muted">Gerenciar</a>
          </li>
          {/*end::Item*/}
        </ul>     
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
     {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
     <button className='btn btn-primary' onClick={e => { openModal() }}><i className="fas fa-plus" />Adicionar</button>
    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}

  {/*begin::Post*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">
      {/*begin::Card*/}
      <div className="card card-custom gutter-b example example-compact">
        {/*begin::Card header*/}
        {/*<div className="card-header">*/}
          {/*begin::Card title*/}
          
         
          {/*begin::Card toolbar*/}
        {/*</div>*/}
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body">

        <LoadingOverlay
          active={loadingSuggestion}
          spinner
        >
      <table className="table table-striped" id="kt_table_users">

        <thead className="bg-light-primary">
          <Headerer
            columns={[
              {name: 'Avatar', id: 'user_featureds.avatar', searchable:false, sortable: false},
              {name: 'Username', id: 'users.username', searchable: true, sortable: true},
              {name: 'Nickname', id: 'users.nickname', searchable: true, sortable: true},
              {name: 'Data Inicial', id: 'user_featureds.date_from', searchable: true, sortable: true},
              {name: 'Data Final', id: 'user_featureds.date_to', searchable: true, sortable: true},
              {name: 'Ordem', id: 'user_featureds.order', searchable: true, sortable: true},
              {name: 'Ação', id: 'action', searchable: false, sortable: false},
            ]}
            setSearchOrig={setSearchSuggestion}
            searchOrig={searchSuggestion}
          />
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {suggestion.map(sug => {
            return (
              <>
                <tr>
                  <td className='text-center'>{sug.avatar !== null && <img className="media-object rounded-circle" src={sug.avatar} width={40} height={40} />}
                      {sug.avatar === null && (
                        <img
                          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                          alt="" width={30} height={30}
                        />
                      )}
                  </td>
                  <td>{sug.username}</td>
                  <td>{sug.nickname}</td>
                  <td className='text-center'>{sug.date_from}</td>
                  <td className='text-center'>{sug.date_to}</td>
                  <td className='text-center'>{sug.order}</td>
                  <td>
                    <div class="dropdown dropdown-inline dropleft">
                      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-more"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" onClick={e => { editSuggestion(sug) }}>Editar</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" onClick={e => { deleteSuggestion(sug) }}>Apagar</a>
                      </div>
                    </div>                    
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      </LoadingOverlay>

      <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loadingSuggestion} />
          
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  
  <Modal size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
    <Modal.Header>
      <Modal.Title>Adicionar Sugestão de Criadores</Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
        <i aria-hidden="true" className="flaticon2-cross" />
      </button>
    </Modal.Header>

    <div className="row ml-3 mr-3 mt-3">
      <div className="form-group col-md-12">
        <label>Nickname / Usuário <span class="text-danger">*</span></label>
        <Select
          options={listCreator}
          placeholder="Selecione..."
          defaultValue={listCreator.find(
            x => x.value === formSuggestion?.id_user
          )}
          onChange={(e) => {setFormSuggestion({...formSuggestion, id_userIncluir: e})}}
        />
      </div>
     </div>

      <div className='row ml-3 mr-3'>
        <div className="col form-group">
          <label>Data de <span class="text-danger">*</span></label>
            <div className="input-group">
            <DatePicker 
              name="dt_inicio"
              onChange={(e) => { setFormSuggestion({...formSuggestion, date_from: e}) }}
              isClearable
              selectsStart
              locale="pt-BR"
              className="form-control"
              placeholderText="dd/mm/aaaa" 
              selected={formSuggestion.date_from ? formSuggestion.date_from : ''}
              dateFormat="dd/MM/y"
              startDate={formSuggestion.date_from ? formSuggestion.date_from : ''}
              endDate={formSuggestion.date_to ? formSuggestion.date_to : ''}
              customInput={
                <InputMask mask="99/99/9999" />}/>
            </div>
        </div>

        <div className="col form-group">
          <label>Data até <span class="text-danger">*</span></label>
            <div className="input-group">
            <DatePicker 
              name="dt_final"
              onChange={(e) => { setFormSuggestion({...formSuggestion, date_to: e}) }}
              isClearable
              locale="pt-BR"
              className="form-control"
              placeholderText="dd/mm/aaaa" 
              selected={formSuggestion.date_to ? formSuggestion.date_to : ''}
              dateFormat="dd/MM/y"
              selectsEnd
              startDate={formSuggestion.date_to ? formSuggestion.date_to : ''}
              endDate={formSuggestion.date_to ? formSuggestion.date_to : ''}
              minDate={formSuggestion.date_from ? formSuggestion.date_from : ''}
              customInput={
                <InputMask mask="99/99/9999" />}/>
            </div>
        </div>

        <div className='col form-group'>
          <label>Ordem <span class="text-danger">*</span></label>
          <input className="form-control"
            value={formSuggestion?.order}
            onChange={(e) => { setFormSuggestion({ ...formSuggestion, order: e.target.value }) }} />
        </div>

     </div>

    <Modal.Footer>
      <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
      <button type="button" onClick={e => { save(); }} className="btn btn-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-save" />Salvar</button>
    </Modal.Footer>
  </Modal>

    </div>
    {/*end::Container*/}
  </div>
    </>
  );

}

export default withRouter(TransacoesGerenciar);                  