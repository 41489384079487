import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";
import Select from 'react-select';

import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite-rtl.css";

import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from '../../components/paginator';
import { Headerer } from '../../components/headerer';
import { Link } from 'react-router-dom';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Plans = () => {

  function formata(val) {
    val = parseFloat(val);
    if (val > 0) {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }
    return '0,00';
  }

  useEffect(() => {
    //document.title = ""
  }, []);

  let subtitle;
  const {addToast} = useToasts();
  const [plans, setPlans] = useState([]);
  const [actPage, setActPage] = useState();
  const [totPage, setTotPage] = useState();
  const [changePageReferreds, setChangePageReferreds] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({field: {}});
  const [listPlans, setListPlans] = useState([]);
  const [formPlans, setFormPlans] = useState({});

  function getPlans(page) {
    setLoading(true);
    Api.post("/plans/list?page=" + page, search).then((rps) => {
      setLoading(false);

      if(rps.data.status!==true){
        //Swal.fire(rps.data.message);
        //return;
      }
      setPlans(rps.data.obj);
      setTotPage(rps.data.last_page);
      setActPage(rps.data.current_page);
    })
  }

  useEffect(() => {
    if (changePageReferreds === null)
      return;
    getPlans(changePageReferreds);
  }, [changePageReferreds])

  useEffect(() => {
    setActPage(1);
    getPlans(1);
  }, [search])

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(){
    setFormPlans({});
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  function editPlans(pla) {  
    
    var ca = JSON.parse(JSON.stringify(pla));
    
    setFormPlans(ca);
    setIsOpen(true);            
  }

  function deletePlans(pla){  
    var ca = JSON.parse(JSON.stringify(pla));

    setLoading(true);

    Swal.fire({
      icon: "question",
      title: 'Confirmação',
      html: 'Deseja excluir o Plano?',
      showCancelButton: true,
      confirmButtonText: `Sim, Confirmar!`,
      cancelButtonText: 'Não, Cancelar.'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        Api.post('/plans/delete', ca).then(rps => {
          if (rps.data.status == true) {
            addToast(rps.data.message, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000
            });
            getPlans();
          }

        })
      }
      setLoading(false);
    })          
  }

  function save() {

    Api.post("/plans/save", formPlans).then((rps) => {

      if(rps.data.status === true){
        addToast(rps.data.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }
      getPlans();
    })
    setIsOpen(false);
  }

  return (
    <>
  {/*begin::Toolbar*/}
  <div className="toolbar" id="kt_toolbar">
    {/*begin::Container*/}
    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
      {/*begin::Page title*/}
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        {/*begin::Title*/}
        <h1 className="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Planos</h1>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <span className="h-20px border-gray-300 border-start mx-4" />
        {/*end::Separator*/}
        {/*begin::Breadcrumb*/}
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          {/*begin::Item*/}
          <li class="breadcrumb-item">
            <a href="" class="text-muted">Gerenciar</a>
          </li>
          {/*end::Item*/}
        </ul>     
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}
     {/* Quando quiser colocar botão no canto direito -- AQUI -- */}
     <button className='btn btn-primary' onClick={e => { openModal() }}><i className="fas fa-plus" />Adicionar</button>
    </div>
    {/*end::Container*/}
  </div>
  {/*end::Toolbar*/}

  {/*begin::Post*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">
      {/*begin::Card*/}
      <div className="card card-custom gutter-b example example-compact">
        {/*begin::Card header*/}
        {/*<div className="card-header">*/}
          {/*begin::Card title*/}
          
         
          {/*begin::Card toolbar*/}
        {/*</div>*/}
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body">

        <LoadingOverlay
          active={loading}
          spinner
        >
      <table className="table table-striped" id="kt_table_users">

        <thead className="bg-light-primary">
          <Headerer
            columns={[
              {name: 'Id', id: 'id', searchable:false, sortable: false},
              {name: 'Descrição', id: 'description', searchable: true, sortable: true},
              {name: 'Assinatura', id: 'subscription_percent', searchable: true, sortable: true},
              {name: 'Gorjeta', id: 'tip_percent', searchable: true, sortable: true},
              {name: 'Publicação', id: 'post_percente', searchable: true, sortable: true},
              {name: 'Crédito em dias', id: 'days_credit', searchable: true, sortable: true},
              {name: 'Ação', id: 'action', searchable: false, sortable: false},
            ]}
            setSearchOrig={setSearch}
            searchOrig={search}
          />
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {plans.map(pla => {
            return (
              <>
                <tr>
                  <td className='text-center'>{pla.id}</td>
                  <td>{pla.description}</td>
                  <td className='text-center'>{formata(pla.subscription_percent)}</td>
                  <td className='text-center'>{formata(pla.tip_percent)}</td>
                  <td className='text-center'>{formata(pla.post_percent)}</td>
                  <td className='text-center'>{pla.days_credit}</td>
                  <td>
                    <div class="dropdown dropdown-inline dropleft">
                      <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="flaticon-more"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" onClick={e => { editPlans(pla) }}>Editar</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" onClick={e => { deletePlans(pla) }}>Apagar</a>
                      </div>
                    </div>                    
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      </LoadingOverlay>

      <Paginator maxPages={totPage} actPage={actPage} setNextPage={setChangePageReferreds} loading={loading} />
          
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}

  {/*end::Post*/}
  
  <Modal size={'lg'} centered show={modalIsOpen} onHide={() => closeModal()}>
    <Modal.Header>
      <Modal.Title>Adicionar Plano</Modal.Title>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
        <i aria-hidden="true" className="flaticon2-cross" />
      </button>
    </Modal.Header>

    <div className="row ml-3 mr-3 mt-3">
      <div className="form-group col-md-10">
        <label>Descrição <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.description}
            onChange={(e) => { setFormPlans({ ...formPlans, description: e.target.value }) }} />
      </div>

      <div className="form-group col-md-2">
        <label>Padrão <span class="text-danger">*</span></label>
        <select className="form-control"
            value={formPlans?.is_default}
            onChange={(e) => { setFormPlans({ ...formPlans, is_default: e.target.value }) }}>
              <option value="">Selecione</option>
              <option value={1}>Sim</option>
              <option value={2}>Não</option>
        </select>
      </div>
     </div>

     <div className="row ml-3 mr-3 mt-3">
      <div className="form-group col-md-3">
        <label>Assinatura (%) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.subscription_percent}
            onChange={(e) => { setFormPlans({ ...formPlans, subscription_percent: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Gorjeta (%) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.tip_percent}
            onChange={(e) => { setFormPlans({ ...formPlans, tip_percent: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Publicação (%) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.post_percent}
            onChange={(e) => { setFormPlans({ ...formPlans, post_percent: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Comissão (%) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.order_percent}
            onChange={(e) => { setFormPlans({ ...formPlans, order_percent: e.target.value }) }} />
      </div>
     </div>

     <div className="row ml-3 mr-3 mt-3">
      <div className="form-group col-md-3">
        <label>Pix (Dias) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.days_pix}
            onChange={(e) => { setFormPlans({ ...formPlans, days_pix: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Carteira (Dias) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.days_wallet}
            onChange={(e) => { setFormPlans({ ...formPlans, days_wallet: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Boleto (Dias) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.days_boleto}
            onChange={(e) => { setFormPlans({ ...formPlans, days_boleto: e.target.value }) }} />
      </div>

      <div className="form-group col-md-3">
        <label>Crédito (%) <span class="text-danger">*</span></label>
        <input className="form-control"
            value={formPlans?.days_credit}
            onChange={(e) => { setFormPlans({ ...formPlans, days_credit: e.target.value }) }} />
      </div>
     </div> 
    <Modal.Footer>
      <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
      <button type="button" onClick={e => { save(); }} className="btn btn-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-save" />Salvar</button>
    </Modal.Footer>
  </Modal>

    </div>
    {/*end::Container*/}
  </div>
    </>
  );

}

export default withRouter(Plans);                  