import React, { useEffect } from "react";
import { withRouter } from "react-router";

const Dashboard = (register) => {

    useEffect(() => {
        console.log(register);
    }, [register])
return (
    <>             
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Facebook</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-facebook-f" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.facebook} placeholder="Facebook" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Instagram</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-instagram-square" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.instagram} placeholder="Instagram" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Twitter</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-twitter" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.twitter} placeholder="Twitter" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">TikTok</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-tiktok" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.tiktok} placeholder="TikTok" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">YouTube</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-youtube" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.youtube} placeholder="YouTube" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Spotify</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-spotify" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.spotify} placeholder="Spotify" />
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Pinterest</label>
            <div className="col-lg-9 col-xl-9">
                <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fab fa-pinterest-p" />
                    </span>
                </div>
                <input type="text" className="form-control form-control-lg form-control-solid" value={register.pinterest} placeholder="Pinterest" />
                </div>
            </div>
        </div>
        
    </>
)
}

export default withRouter(Dashboard); 